import { breakpoints, sidebarWidth } from "./styles";
import WebMercatorViewport, { getFlyToDuration } from "@math.gl/web-mercator";
import { MapBounds, WordpressMapBounds } from "../types";
import { ViewportProps } from "@math.gl/web-mercator/dist/normalize-viewport-props";

export const calculateViewport = (boundingBox: MapBounds): WebMercatorViewport => {
  const padding = {
    left: matchMedia(breakpoints[30]).matches ? sidebarWidth : 0,
    right: 0,
    top: 0,
    bottom: 0,
  };
  const { innerWidth, innerHeight } = window;
  const viewport = new WebMercatorViewport({
    width: innerWidth,
    height: innerHeight,
  }).fitBounds(boundingBox, {
    padding,
  });

  return viewport;
};

export const generateBoundingBox = (bbox: WordpressMapBounds): MapBounds | undefined => {
  const { north, west, south, east } = bbox;
  const parsedBbox: MapBounds = [
    [parseFloat(west), parseFloat(north)],
    [parseFloat(east), parseFloat(south)],
  ];

  if (parsedBbox.some((c) => c.some(isNaN))) {
    console.error(`Invalid bounding box: [(${north}, ${west}), (${south}, ${east})]`);
    return undefined;
  }

  return parsedBbox;
};

export const flyToDuration = (from: ViewportProps, to: ViewportProps) => {
  const { innerWidth, innerHeight } = window;
  const dur = getFlyToDuration(
    {
      latitude: from.latitude ?? 0,
      longitude: from.longitude ?? 0,
      zoom: from.zoom ?? 0,
      // @ts-ignore
      width: innerWidth,
      height: innerHeight,
    },
    {
      latitude: to.latitude ?? 0,
      longitude: to.longitude ?? 0,
      zoom: to.zoom ?? 0,
      // @ts-ignore
      width: innerWidth,
      height: innerHeight,
    }
  );

  return isNaN(dur) ? 1500 : dur;
};
