import Div100vh from "react-div-100vh";
import styled from "styled-components";
import { breakpoints } from "./utils/styles";

export const Layout = styled(Div100vh)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  width: 100vw;

  // TODO: figure out how to use this with survey
  /* &.mouse-user {
    *:focus {
      outline: none;
    }
  } */

  @media ${breakpoints[20]} {
    grid-template-columns: var(--sidebar-width) minmax(var(--sidebar-width), 30vw) 1fr;

    &.focus {
      grid-template-columns: minmax(var(--sidebar-width), 30vw) minmax(var(--sidebar-width), 30vw) 1fr;
    }
  }

  @media ${breakpoints[30]} {
    grid-template-columns: var(--sidebar-width) minmax(0px, 80ch) 1fr;

    &.focus {
      grid-template-columns: minmax(var(--sidebar-width), 55ch) minmax(var(--sidebar-width), 80ch) 1fr;
    }
  }
`;

export const Loader = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    width: 70%;
    height: 100px;
  }

  @media ${breakpoints[30]} {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    .inner {
      width: 50%;
    }
  }
`;

export const SkeletonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  transform: translate(-50%, -50%);
`;

export const ConceptBar = styled.div`
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  position: relative;
  padding: var(--space-10);
  padding-left: var(--space-30);
  z-index: 100;
  width: 100%;
  background: var(--color-primary-50);
  color: var(--color-white);
  font-size: var(--font-size-10);
`;
