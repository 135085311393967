import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";
import { MapLayerMouseEvent } from "react-map-gl";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import { useAppState } from "./AppContext";
import { useRouteState } from "./hooks/useRouteState";
import { AppEntrance } from "./types";
import { KrpanoType } from "./libs/krpano";
import { krpanoId } from "./utils/panoUtils";
import { breakpoints } from "./utils/styles";
import { isPanoTourPoint } from "./utils/tourUtils";

type InterfaceState = {
  sidebarOpen: boolean;
  currentEntrance: AppEntrance;
  legendClicked?: {
    features: MapLayerMouseEvent["features"];
    lngLat: MapLayerMouseEvent["lngLat"];
  };
  question?: {
    pickingLocation?: boolean;
    location?: {
      latitude: number;
      longitude: number;
    };
  };
  vr: {
    /** A hashmap of pois converted for VR usage */
    pois: {
      [contentSlug: string]: string | undefined;
    };
  };
};

export type ParticipationState = {
  layerSlug: string;
  panoSlug: string;
  scenarioSlug: string;
  navigatedPano: boolean;
  panoLookat?: {
    yaw: number;
    pitch: number;
    fov: number;
  };
};

// @ts-ignore
const initialInterfaceState: InterfaceState = {
  sidebarOpen: window ? window.innerWidth > window.innerHeight : true,
  vr: { pois: {} },
};

const initialParticipationState: ParticipationState = {
  layerSlug: "",
  panoSlug: "",
  scenarioSlug: "",
  navigatedPano: false,
};

const InterfaceStateContext = createContext<{
  interfaceState: InterfaceState;
  setInterfaceState: Dispatch<SetStateAction<InterfaceState>>;
  participationState: ParticipationState;
  setParticipationState: Dispatch<SetStateAction<ParticipationState>>;
  isInVr: boolean;
  setIsInVr: Dispatch<SetStateAction<boolean>>;
}>({
  interfaceState: initialInterfaceState,
  setInterfaceState: () => {},
  participationState: initialParticipationState,
  setParticipationState: () => {},
  isInVr: false,
  setIsInVr: () => {},
});

export const InterfaceStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [interfaceState, setInterfaceState] = useState(initialInterfaceState);
  const [participationState, setParticipationState] = useState(initialParticipationState);
  const { state: appState } = useAppState();
  const location = useLocation();
  const history = useHistory();
  const { panoSlug, tourSlug, tourPointSlug } = useRouteState(true);
  const isDesktop = useMediaQuery({ query: breakpoints[10] });

  const [isInVr, setIsInVr] = useState<boolean>(() => {
    const krpano = document.getElementById(krpanoId) as KrpanoType | null;
    return krpano?.get("webvr.isenabled") ?? false;
  });

  useEffect(() => {
    if (appState.initializing) return;

    if (!interfaceState.currentEntrance) {
      if (
        appState.landingPage &&
        (location.pathname === "/" || /^\/[@!]/.test(location.pathname))
      ) {
        setInterfaceState((state) => ({
          ...state,
          sidebarOpen: false,
        }));
      }
      if (appState.availableEntrances.length === 1) {
        const currentEntrance = appState.availableEntrances[0];
        if (!appState.landingPage) {
          setInterfaceState((state) => ({
            ...state,
            currentEntrance,
          }));
        }
      }
      if (location.pathname === "/" || /^\/[@!]/.test(location.pathname)) return;

      if (
        location.pathname.startsWith("/map") &&
        appState.availableEntrances.some((e) => e.type === "map")
      ) {
        const mapEntrance = appState.availableEntrances.find((e) => e.type === "map")!;
        const pano =
          (panoSlug && appState.panos.find((p) => p.slug === panoSlug)) || appState.panos[0];
        setInterfaceState((state) => ({
          ...state,
          currentEntrance: {
            type: "map",
            sidebarOpen: mapEntrance.sidebarOpen,
            pano: pano,
          },
        }));
        return;
      }

      if (location.pathname.startsWith("/pano")) {
        const pano = appState.panos.find((p) => p.slug === panoSlug);
        if (pano) {
          const panoEntrances = appState.availableEntrances.filter(
            (e) => e.type === "pano" || e.type === "vcr"
          );
          switch (panoEntrances.length) {
            case 0:
              setInterfaceState((state) => ({
                ...state,
                currentEntrance: {
                  type: pano.icon === "vcr" ? "vcr" : "pano",
                  sidebarOpen: appState.availableEntrances[0].sidebarOpen,
                  pano,
                },
              }));
              break;

            case 1:
              setInterfaceState((state) => ({
                ...state,
                sidebarOpen: panoEntrances[0].sidebarOpen,
                currentEntrance: panoEntrances[0],
              }));
              break;

            case 2:
              const currentPanoEntrance =
                panoEntrances.find((e) => e.pano.id === pano.id) ??
                panoEntrances.find((e) => e.type === (pano.icon === "vcr" ? "vcr" : "pano"))!;
              setInterfaceState((state) => ({
                ...state,
                currentEntrance: currentPanoEntrance,
              }));
              break;
          }
          return;
        }
      }

      if (location.pathname.startsWith("/tour")) {
        const tour = appState.tours.find((t) => t.slug === tourSlug);
        if (tour) {
          const tourPoint =
            (tourPointSlug && tour.points.find((p) => p.slug === tourPointSlug)) || tour.points[0];
          const pano = isPanoTourPoint(tourPoint) ? tourPoint.data.pano : appState.panos[0];

          setInterfaceState((state) => ({
            ...state,
            currentEntrance: {
              type: tourPoint.type,
              sidebarOpen: false,
              pano,
            },
          }));
          return;
        }
      }

      console.error("Invalid entrypoint");
      history.replace("/");
    }
  }, [
    appState.initializing,
    appState.availableEntrances,
    appState.panos,
    appState.tours,
    interfaceState.currentEntrance,
    setInterfaceState,
    appState.tourPoints,
    location.pathname,
    history,
    panoSlug,
    tourSlug,
    tourPointSlug,
    appState.landingPage,
  ]);

  useEffect(() => {
    if (interfaceState.currentEntrance) {
      setInterfaceState((state) => ({
        ...state,
        sidebarOpen: isDesktop && interfaceState.currentEntrance.sidebarOpen,
      }));
    }
  }, [interfaceState.currentEntrance, setInterfaceState, isDesktop]);

  return (
    <InterfaceStateContext.Provider
      value={{
        interfaceState,
        setInterfaceState,
        participationState,
        setParticipationState,
        isInVr,
        setIsInVr,
      }}
    >
      {children}
    </InterfaceStateContext.Provider>
  );
};

export const useInterfaceState = () => useContext(InterfaceStateContext);
