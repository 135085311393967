import styled from "styled-components";
import { breakpoints } from "./../../utils/styles";
import { IconSpan } from "../../shared/components/Icon";
import { ParagraphSmall } from "../../shared/components/ParagraphStyles";

export const StyledSidebarToggle = styled.div`
  /* display: none; */

  /* @media ${breakpoints[30]} { */
  position: absolute;
  border-radius: 50%;
  right: 8px;
  top: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  transition: background-color 200ms var(--bezier-a), transform 200ms var(--bezier-a);
  background-color: rgba(25, 24, 37, 0.8);

  &:hover {
    background-color: rgba(25, 24, 37, 0.95);
  }

  & ${IconSpan} {
    margin: auto;
    color: var(--color-neutral-70);

    width: 12px;
    height: 12px;

    & svg {
      stroke: white;
      stroke-width: 3px;
      width: 12px;
      height: 12px;
    }
  }
`;

export const StyledLayoutSidebar = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  z-index: 80;
  width: 80vw;

  pointer-events: none;

  transform: translateX(-110%);
  transition: transform 300ms var(--bezier-a);

  @media ${breakpoints[10]} {
    width: var(--sidebar-width);
  }

  @media ${breakpoints[30]} {
    transform: translateX(0);
    pointer-events: auto;
    width: auto;
    grid-column: 1 / 2;
    overflow: hidden;
  }

  /* Open sidebar on mobile */
  &.is-open {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  /* Open sidebar on desktop */
  &.is-closed {
    @media ${breakpoints[30]} {
      transform: translateX(-100%);

      & + .is-open {
        transform: translateX(calc(-1 * var(--sidebar-width) + 10px));
      }
    }

    & ${StyledSidebarToggle} {
      /* right: -16px;
      top: 0;
      width: 26px;
      height: 26px;
      transform: rotate(180deg);
      background-color: var(--color-white);
      border-top-right-radius: 0px; */
    }
  }

  /* Hide sidebar when going in focus mode */
  &.is-hidden {
    @media ${breakpoints[30]} {
      transform: translateX(calc(-100% - 20px));
    }
  }
`;

export const StyledSidebar = styled.div`
  /* Layout > Sidebar */
  background: var(--color-white);
  box-shadow: var(--box-shadow-30);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  height: 100%;

  @media ${breakpoints[10]} {
    width: var(--sidebar-width);
  }

  @media ${breakpoints[30]} {
    grid-column: 1 / 2;
  }
`;

export const Scroll = styled.div`
  overflow: hidden auto;
  display: flex;
  flex-flow: column nowrap;
`;

export const ScrollInner = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
`;

export const Content = styled.div`
  padding: 16px 16px 0px 16px;

  &.footer {
    align-self: flex-end;
    width: 100%;
    font-size: var(--font-size-30);

    &.disclaimer {
      background: var(--color-neutral-10);

      & ${ParagraphSmall} {
        font-size: var(--font-size-10);
      }

      a {
        color: inherit;
        font-weight: bold;

        &:hover {
          color: var(--color-primary-50);
        }
      }
    }
  }
`;

export const SocialMediaButton = styled.a`
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
  margin-left: var(--space-20);
  cursor: pointer;

  &:not(:hover) {
    [fill]:not([fill="#fff"]) {
      fill: var(--color-neutral-70);
    }
  }

  &:after {
    display: none !important;
  }
`;
