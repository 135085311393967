import React from "react";
import ReactDOM from "react-dom";
import { ReactComponent as CloseIcon } from "./../../images/close.svg";
import styled from "styled-components";
import Icon from "./Icon";
import { breakpoints } from "../../utils/styles";

export type ModalProps = React.PropsWithChildren<{
  open: boolean;
  onClose?: Function;
}>;

export const Modal = ({ open, onClose, children }: ModalProps) => {
  if (!open) return null;

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <StyledModal>
        {onClose && (
          <ModalClose onClick={() => onClose()}>
            <CloseIconSpan>
              <CloseIcon />
            </CloseIconSpan>
          </ModalClose>
        )}
        {children}
      </StyledModal>
    </>,
    document.getElementById("portal")!
  );
};

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(225, 225, 225, 0.6);
  backdrop-filter: blur(7px);
`;

const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--space-default);
  border-radius: var(--border-radius-10);
  min-height: 100px;
  min-width: 200px;
  max-width: 600px;
  z-index: 100;
  background: white;
  box-shadow: var(--box-shadow-30);
  width: 90%;
  max-height: 100%;
  overflow-y: auto;

  h1 {
    padding-right: 3rem;
  }

  @media ${breakpoints[20]} {
    width: unset;
  }
`;

const CloseIconSpan = styled(Icon)`
  margin: auto;
  width: 18px;
  height: 18px;

  & svg {
    stroke: var(--color-white);
    transition: stroke 200ms var(--bezier-a);
  }
`;

const ModalClose = styled.div`
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  background-color: #19182566;
  transition: background-color 200ms var(--bezier-a);

  &:hover {
    background-color: #19182599;
  }
`;
