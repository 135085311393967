import { CustomLayerInterface, Map } from "mapbox-gl";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export interface ThreeJsMapboxLayerOptions {
  id: string;
  url: string;
  transform: {
    translate: THREE.Vector3;
    rotate: THREE.Euler;
    scale: THREE.Vector3;
  };
}

export class ThreeJsMapboxLayer implements CustomLayerInterface {
  readonly id = this.options.id;
  readonly type = "custom";

  private camera = new THREE.Camera();
  private scene = new THREE.Scene();
  private loader = new THREE.LoadingManager();

  private renderer!: THREE.WebGLRenderer;

  map!: Map;

  constructor(private options: ThreeJsMapboxLayerOptions) {}

  onAdd(map: Map, gl: WebGLRenderingContext) {
    // create two three.js lights to illuminate the model
    const ambientLight = new THREE.AmbientLight(0x777777, 0.5);
    this.scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff);
    directionalLight.position.set(0, 70, 100).normalize();
    this.scene.add(directionalLight);

    const gltfLoader = new GLTFLoader(this.loader);
    gltfLoader.load(this.options.url, (gltf) => this.scene.add(gltf.scene));
    this.map = map;

    // use the Mapbox GL JS map canvas for three.js
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;
  }

  render(gl: WebGLRenderingContext, matrix: number[]) {
    const rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      this.options.transform.rotate.x
    );
    const rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      this.options.transform.rotate.y
    );
    const rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      this.options.transform.rotate.z
    );

    const m = new THREE.Matrix4().fromArray(matrix);
    const l = new THREE.Matrix4()
      .makeTranslation(
        this.options.transform.translate.x,
        this.options.transform.translate.y,
        this.options.transform.translate.z
      )
      .scale(
        this.options.transform.scale
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);

    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }
}
