export enum SurveyState {
  Closed,
  Open,
}

export enum QuestionnaireType {
  Questionnaire,
  ContactForm,
}

export enum RestrictionLayerType {
  Mandatory,
  Restricted,
}
