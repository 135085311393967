import styled from "styled-components";
import tick from "./../../images/tick.svg";
import { breakpoints } from "../../utils/styles";
import { Link } from "react-router-dom";
import Button from "../../shared/components/Button";

export const StyledMapLayerGroups = styled.div`
  margin-bottom: var(--space-default);
  a {
    color: var(--color-neutral-90);
    text-decoration: none;
  }
`;

export const LayerGroupTitle = styled.div`
  font-weight: 400;
  line-height: var(--line-height-20);
`;

export const LayerGroupInput = styled.div`
  margin-right: 16px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid var(--color-neutral-30);
  display: flex;
  transition: box-shadow 100ms var(--bezier-a);

  &:before {
    opacity: 0;
    height: 13px;
    width: 13px;
    content: " ";
    margin: auto;
    color: var(--color-white);
    background-image: url(${tick});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`;

export const LayerGroupLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
`;

export const LayerGroupZoomButton = styled(Button)`
  padding: 6px 12px 8px 12px;
  background: var(--color-primary-50);
  color: var(--color-white);
  line-height: var(--line-height-20);
  margin-bottom: var(--space-default);

  &:hover {
    background: var(--color-primary-70);
    color: var(--color-white);
  }
`;

export const LayerGroupContent = styled.div`
  display: none;
  padding: 0 16px 0px 52px;
  font-size: var(--font-size-20);
  line-height: var(--line-height-20);
  color: var(--color-neutral-50);

  > a {
    color: var(--color-primary-50);
    display: block;
    margin: var(--space-stack-default);

    > svg {
      stroke-width: 2px;
      width: 12px;
      height: 12px;
      stroke: var(--color-primary-50);
    }
  }
`;

export const LegendList = styled.ul`
  list-style: none;
  margin: var(--space-stack-default);

  &.halves {
    @media ${breakpoints[30]} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: var(--space-20);
    }
  }
`;

export const LegendListItem = styled.li`
  display: flex;
  line-height: var(--line-height-20);
  margin: var(--space-stack-20);
`;

export const LegendListSymbol = styled.span`
  display: block;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  background: var(--color-neutral-10);
  border-radius: var(--border-radius-15);
  margin-right: var(--space-25);
`;

export const LegendListItemColor = styled.span`
  flex: 0 0 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 16px;
`;

export const LegendListItemTitle = styled.span`
  font-size: var(--font-size-20);
  color: var(--color-neutral-50);
`;

export const LayerGroupListItem = styled.div`
  display: block;

  & + & {
    border-top: 1px solid var(--color-neutral-10);
  }

  &:only-child {
    & ${LayerGroupInput} {
      display: none;
    }

    & ${LayerGroupContent} {
      padding: 0 16px;
    }

    & ${LayerGroupLink}, & ${LayerGroupTitle} {
      cursor: default;
      &:hover {
        color: var(--color-neutral-90);
      }
    }
  }

  &.active {
    &:not(:only-child) {
      & ${LayerGroupTitle} {
        color: var(--color-primary-50);
      }
    }

    & ${LayerGroupInput} {
      background: var(--color-primary-50);
      border-color: var(--color-primary-50);
      box-shadow: 0px 0px 0px 4px var(--color-primary-20);

      &:before {
        opacity: 1;
      }
    }

    & ${LayerGroupContent} {
      display: block;
    }
  }
`;

export const LayerGroupScenarioSwitchContainer = styled.ul`
  list-style: none;
  margin-bottom: var(--space-default);
`;

export const LayerGroupScenarioLink = styled(Link)`
  display: inline-block;
  font-size: var(--font-size-20);
  color: var(--color-neutral-70);
  border-radius: var(--border-radius-default);
  padding: 6px 12px 8px 12px;
  background-color: var(--color-neutral-10);
  font-weight: 500;
  line-height: var(--line-height-20);
  margin-bottom: var(--space-10);
  margin-right: var(--space-10);
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-20);
    color: var(--color-neutral-70);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    background-color: var(--color-primary-50);
    color: var(--color-white);
  }
`;
