import { useCallback, useMemo } from "react";
import {
  getLocalStorage,
  getSessionStorage,
  mappedLocalStorage,
  mappedSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from "../utils/corsUtils";

type UseStorageReturn = [string | null, (value: string) => void, () => void];

let localStorageUpdate = 0;
let sessionStorageUpdate = 0;

export const useLocalStorage = (key: string): UseStorageReturn => {
  const update = localStorageUpdate;

  const value = useMemo(() => {
    if (mappedLocalStorage.has(key)) return mappedLocalStorage.get(key)!;

    getLocalStorage(key).then(value => {
      if (update === localStorageUpdate && value) {
        mappedLocalStorage.set(key, value);
        localStorageUpdate++;
      }
    });

    return null;
  }, [key, update]);
  
  const setValue = useCallback(
    async (value: string) => {
      await setLocalStorage(key, value);
      mappedLocalStorage.set(key, value);
      localStorageUpdate++;
    },
    [key]
  );

  const removeValue = useCallback(async () => {
    await removeLocalStorage(key);
    mappedLocalStorage.delete(key);
    localStorageUpdate++;
  }, [key]);

  return [value, setValue, removeValue];
};

export const useSessionStorage = (key: string): UseStorageReturn => {
  const update = sessionStorageUpdate;
  
  const value = useMemo(() => {
    if (mappedSessionStorage.has(key)) return mappedSessionStorage.get(key)!;

    getSessionStorage(key).then(value => {
      if (update === sessionStorageUpdate && value) {
        mappedSessionStorage.set(key, value);
        sessionStorageUpdate++;
      }
    });

    return null;
  }, [key, update]);

  const setValue = useCallback(
    async (value: string) => {
      await setSessionStorage(key, value);
      mappedSessionStorage.set(key, value);
      sessionStorageUpdate++;
    },
    [key]
  );

  const removeValue = useCallback(async () => {
    await removeSessionStorage(key);
    mappedSessionStorage.delete(key);
    sessionStorageUpdate++;
  }, [key]);

  return [value, setValue, removeValue];
};
