import {
  AppConfig,
  AppEntrance,
  MapBounds,
  MenuItem,
  Page,
  Pano,
  Scenario,
  WordpressConfig,
} from "../types";
import { upsert } from "./areaUtils";
import { generateBoundingBox } from "./mapUtils";

export const generateConfigFromWordpress = (
  config: WordpressConfig,
  scenarios: Scenario[],
  panoramas: Pano[],
  pages: Page[]
): AppConfig => {
  const acf = config.acf;

  let initialBounds: MapBounds | undefined = undefined;

  if (acf.initial_bounding_box) {
    initialBounds = generateBoundingBox(acf.initial_bounding_box);
  }

  if (!initialBounds) {
    const coords = acf.initial_bounds?.split(",").map((v) => parseFloat(v));

    initialBounds = coords
      ? [
          [coords[1], coords[0]],
          [coords[3], coords[2]],
        ]
      : [
          [0, 0],
          [0, 0],
        ];
  }

  const minZoom = config.acf.min_zoom;
  const maxZoom = config.acf.max_zoom;
  const areaInflectionPoint = config.acf.area_inflection_point;

  const { name, logo, descriptions, language, menu_items, socials } = acf;
  const menuItems = generateMenuItems(menu_items || []);

  const defaultScenario =
    scenarios.find((scenario) => scenario.id === acf.default_scenario) || scenarios[0];
  const teaseScenario = scenarios.find((scenario) => scenario.id === acf.tease_scenario);

  const availableEntrances: AppEntrance[] = [];
  const landingPage =
    acf.landing_page !== undefined ? pages.find((p) => p.id === acf.landing_page) : undefined;

  if (acf.landing_page) {
    acf.platform_entrances.forEach((type) => {
      const sidebarOpen = acf[`entrance_${type}_menu_open`];
      let pano = panoramas[0];

      switch (type) {
        case "pano":
          pano = panoramas.find((pano) => pano.id === acf.entrance_pano) ?? pano;
          break;

        case "vcr":
          pano = panoramas.find((pano) => pano.id === acf.entrance_vcr) ?? pano;
          break;
      }

      upsert("type", availableEntrances, { type, sidebarOpen, pano });
    });
  } else if (acf.platform_base) {
    const pano = panoramas.find((pano) => pano.id === acf.default_panorama) ?? panoramas[0];

    availableEntrances.push({
      type: acf.platform_base === "panorama" ? (pano.icon === "vcr" ? "vcr" : "pano") : "map",
      sidebarOpen: acf.platform_basis_menu_open,
      pano,
    });
  }

  return {
    name,
    logo,
    companyName: acf.company_name,
    companyUrl: acf.company_url ?? undefined,
    description: descriptions,
    enableEmbed: acf.enable_embed,
    enableEmbedAncestors: acf.enable_embed_ancestors,
    language,
    landingPage,
    availableEntrances,
    minimapEnabled: acf.minimap_enabled ?? true,
    panoramaFolder: acf.panorama_folder,
    teaseScenario,
    teaseIdletime: (teaseScenario && acf.tease_idletime) ?? 0,
    defaultScenarioSlug: defaultScenario.slug,
    mapConfig: {
      minZoom: minZoom ? parseFloat(minZoom) : 0,
      maxZoom: maxZoom ? parseFloat(maxZoom) : 22,
      areaInflectionPoint: areaInflectionPoint ? parseFloat(areaInflectionPoint) : 0,
      initialBounds,
      accessToken: acf.mapbox_access_token,
      style: acf.mapbox_style_url,
      layerGroups: [],
      autozoomToActiveLayers: Boolean(acf.autozoom_to_maplayers),
    },
    showPoiLabels: acf.show_poi_labels ?? true,
    menuItems,
    socials,
    qaSurveyId: acf.resource_qa_survey,
  };
};

const generateMenuItems = (menuItems: WordpressConfig["acf"]["menu_items"]): MenuItem[] => {
  return menuItems?.map((menu) => {
    return {
      type: menu.items.menu_type,
      draft: false,
      icon: menu.items.icon,
      name: menu.items.name,
      description: menu.items.description,
      page: menu.items.page_link,
      children: [],
    };
  });
};
