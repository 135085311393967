import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRouteState } from "../../hooks/useRouteState";
import { useAppState } from "../../AppContext";
import {
  Hotspot,
  PanoScenario,
  Pano as PanoType,
  UseStateReturn,
  PanoAction,
  PanoActionTypeMap,
  PanoHotspotTypeMap,
} from "../../types";
import { KrpanoType } from "../../libs/krpano";
import classNames from "classnames";
import { MarkerTypes } from "../../shared/components/MarkerImages";
import { imagePanoXml, videoPanoXml } from "../../utils/panoXmlUtils";
import { PanoScenarioSwitch } from "./PanoScenarioSwitch";
import {
  PanoContainer,
  PanoCloseLink,
  CloseIconSpan,
  PanoFade,
  PanoMapWrapper,
  PanoExcerpt,
} from "./PanoStyles";
import { ReactComponent as CloseIcon } from "./../../images/close.svg";
import { ReactComponent as VrIcon } from "./../../images/vr.svg";
import { ReactComponent as TourIcon } from "./../../images/vr.svg";
import { ReactComponent as BubbleIcon } from "./../../images/bubble.svg";
import { ReactComponent as ShareIcon } from "./../../images/share.svg";
import { ReactComponent as MapIcon } from "./../../images/map.svg";
import ActionList from "../../shared/components/ActionList";
import Action from "../../shared/components/Action";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { Route, useHistory } from "react-router-dom";
import { PanoVideoControls } from "./PanoVideoControls";
import { isPanoTourPoint } from "../../utils/tourUtils";
import { shareView } from "../../utils/sharedUtils";
import { ShareModal } from "../Modals/ShareModal";
import { useInterfaceState } from "../../InterfaceContext";
import { isPanoFaq } from "../../utils/faqUtils";
import { usePanoUrl } from "../../hooks/usePanoUrl";
import { usePoiUrl } from "../../hooks/usePoiUrl";
import { useQuestionUrl } from "../../hooks/useQuestionUrl";
import { usePanoState } from "../../hooks/usePanoState";
import { useSetPanoState } from "../../hooks/useSetPanoState";
import { MiniMap } from "./MiniMap";
import IsDesktop from "../../shared/components/IsDeskop";
import { useUrlState } from "../../hooks/useUrlState";
import { Capability, useCapability } from "../../hooks/useCapability";
import { useEditState } from "../../EditContext";
import { getTokenValue } from "../../TokenStyles";
import { PanoMap } from "./PanoMap";
import IsMobile from "../../shared/components/IsMobile";
import { useMapUrl } from "../../hooks/useMapUrl";
import { PanoActions } from "./PanoActions";
import { useContentUrl } from "../../hooks/useContentUrl";
import {
  getPanoIcon,
  getPoiIcon,
  isOpenUrlHotspot,
  isPointHotspot,
  isPolygonHotspot,
  isPolylineHotspot,
  isSwitchScenarioHotspot,
  krpanoId,
  removeHotspot,
} from "../../utils/panoUtils";
import { PanoAudioControls } from "./PanoAudioControls";
import { useSessionStorage } from "../../hooks/useStorage";
import { useFeature, Feature } from "../../hooks/useFeature";
import Skeleton from "react-loading-skeleton";
import { SkeletonContainer } from "../../AppStyles";
import { useUpdateStateUrl } from "../../hooks/useUpdateStateUrl";
import { messages } from "../../global-intl-messages";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { makeKeyDownHandler } from "../../utils/a11y-utils";
import { isLocalUrl } from "../../utils/urlUtils";

const Krpano = React.lazy(() => import("./Krpano"));
const krpanoZoomFactor = 1.5;

export default function Pano() {
  const routeState = useRouteState(true);
  const setPanoState = useSetPanoState();
  const { state } = useAppState();
  const { interfaceState, setInterfaceState, isInVr } = useInterfaceState();
  const { participationState } = useInterfaceState();
  const urlState = useUrlState();
  const prevPano = useRef<any>();
  const shouldGoBack = useRef(false);
  const [switchScenario, setSwitchScenario] = useState<string | null>(null);
  const history = useHistory();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const panoUrl = usePanoUrl();
  const poiUrl = usePoiUrl();
  const contentUrl = useContentUrl();
  const questionUrl = useQuestionUrl();
  const mapUrl = useMapUrl();
  const updateStateUrl = useUpdateStateUrl();
  const panoState = usePanoState();
  const [loadedPano] = useSessionStorage("loadedPano");
  const [disablePanoScenarioTeaser, setDisablePanoScenarioTeaser] = useSessionStorage(
    "disablePanoScenarioTeaser"
  );
  const canEditSep = useCapability(Capability.EditSep);
  const { editing, setEditing, target, setTarget } = useEditState();
  const participating = Boolean(routeState.participationItemSlug);
  const [faded, setFaded] = useState(false);
  const [teasing, setTeasing] = useState(false);
  const [sidebarWasOpen] = useState(interfaceState.sidebarOpen);
  const shareAvailable = useFeature(Feature.Sharing);
  const askQuestionAvailable = useFeature(Feature.AskQuestion);
  const vrAvailable = useFeature(Feature.VR);
  const intl = useIntl();

  const krpano = document.getElementById(krpanoId) as KrpanoType | undefined;

  const showFade = useMemo(() => {
    return loadedPano === null && interfaceState.currentEntrance.type !== "map";
  }, [loadedPano, interfaceState.currentEntrance.type]);

  const scenarioSlug = routeState.scenarioSlug || state.defaultScenarioSlug;

  let panoScenario: PanoScenario | undefined;
  let pano: PanoType | undefined;

  if (routeState.tourPointSlug) {
    const tourPoint = state.tourPoints.find((p) => p.slug === routeState.tourPointSlug);
    if (tourPoint && isPanoTourPoint(tourPoint)) {
      pano = state.panos.find((pano) => {
        const match = pano.slug === tourPoint.data.pano.slug;
        if (match) {
          panoScenario = pano.scenarios.find(
            (scenario) => scenario.slug === tourPoint.scenarioSlug
          );
          return !!panoScenario;
        }
        return false;
      });
    }
  } else if (participationState.panoSlug && !participationState.navigatedPano) {
    pano = state.panos.find((p) => {
      const match = p.slug === participationState.panoSlug;
      if (match) {
        panoScenario =
          p.scenarios.find((scenario) => scenario.slug === participationState.scenarioSlug) ||
          p.scenarios.find((scenario) => scenario.slug === scenarioSlug) ||
          p.scenarios[0];
        return !!panoScenario;
      }
      return false;
    });
  } else {
    pano = state.panos.find((pano) => {
      const match = pano.slug === routeState.panoSlug;
      if (match) {
        panoScenario =
          (participationState.scenarioSlug
            ? pano.scenarios.find((scenario) => scenario.slug === participationState.scenarioSlug)
            : pano.scenarios.find((scenario) => scenario.slug === scenarioSlug)) ||
          pano.scenarios[0];
        return !!panoScenario;
      }
      return false;
    });
  }

  useEffect(() => {
    if (editing && pano && pano.id !== target) {
      setTarget(pano.id);
    }
  }, [editing, target, setTarget, pano]);

  const storeScenarioSwitched = useCallback(
    (slug: string) => {
      if (routeState.activeScenarioSlug !== slug && disablePanoScenarioTeaser === null)
        setDisablePanoScenarioTeaser("true");
    },
    [routeState.activeScenarioSlug, disablePanoScenarioTeaser, setDisablePanoScenarioTeaser]
  );

  useEffect(() => {
    if (switchScenario) {
      storeScenarioSwitched(switchScenario);
      setSwitchScenario(null);
      history.push(updateStateUrl({ scenarioSlug: switchScenario }, poiUrl(), true));
    }
  }, [history, poiUrl, switchScenario, setSwitchScenario, updateStateUrl, storeScenarioSwitched]);

  useEffect(() => {
    prevPano.current = pano?.slug;
    switch (true) {
      case editing:
        setInterfaceState({ ...interfaceState, sidebarOpen: true });
        break;
      case interfaceState.currentEntrance.type === "map":
        setInterfaceState({ ...interfaceState, sidebarOpen: false });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interfaceState.currentEntrance.type, editing]);

  useEffect(() => {
    return () =>
      setInterfaceState((currentState) => ({ ...currentState, sidebarOpen: sidebarWasOpen }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Register escape key
    if (prevPano.current !== pano?.slug) shouldGoBack.current = true;
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (krpano?.get("webvr.isenabled")) {
          krpano.call("webvr.exitvr()");
        } else if (pano?.returnAtEndOfVideo && shouldGoBack.current) {
          history.goBack();
        } else if (!urlState.embed && interfaceState.currentEntrance.type === "map") {
          history.push(mapUrl());
        }
      }
    };
    window.addEventListener("keydown", handleEscapeKey, false);
    return () => window.removeEventListener("keydown", handleEscapeKey, false);
  }, [mapUrl, pano, urlState.embed, history, krpano, interfaceState.currentEntrance.type]);

  const urlSwitch = useCallback(
    (type: MarkerTypes, slug: string) => {
      switch (type) {
        case MarkerTypes.Pano:
          return panoUrl(slug);
        case MarkerTypes.Faq:
          return poiUrl(slug);
        case MarkerTypes.Info:
        case MarkerTypes.Question:
        case MarkerTypes.Default:
        default:
          return !participating ? contentUrl(slug) : poiUrl(slug);
      }
    },
    [contentUrl, panoUrl, poiUrl, participating]
  );

  const panoLinkHotspots = useMemo(() => {
    const hotspots: Hotspot[] = [];

    pano?.panoLinks?.forEach((panoLink, i) => {
      const pano = state.panos.find((p) => p.id === panoLink.id);
      const scenario = pano?.scenarios.find((s) => s.id === panoScenario?.id);
      if (pano && scenario) {
        const iconType = pano.icon === "auto" ? (scenario.isVideo ? "video" : "pano") : pano.icon;

        hotspots.push({
          slug: `${pano.slug}-${i}`,
          coordinates: panoLink.coordinates,
          type: panoLink.type === "point" ? pano.type : panoLink.type,
          url: panoUrl(panoLink.slug),
          title: isPointHotspot(panoLink) ? pano.title : "",
          icon: isPointHotspot(panoLink)
            ? getPanoIcon(
                pano.type,
                iconType,
                getTokenValue(panoLink.color ?? "--color-primary-50")
              )
            : null,
          border:
            isPolygonHotspot(panoLink) || isPolylineHotspot(panoLink) ? panoLink.border : undefined,
          fill: isPolygonHotspot(panoLink) ? panoLink.fill : undefined,
          allowHighlight: isPolygonHotspot(panoLink) ? panoLink.allowHighlight : true,
        });
      }
    });

    return hotspots;
  }, [pano, panoScenario, panoUrl, state.panos]);

  const poiHotspots = useMemo(() => {
    const hotspots: Hotspot[] = [];

    panoScenario?.pois.forEach((poi, i) => {
      const scenario = pano?.scenarios.find((s) => s.slug === panoScenario?.slug);
      if (pano && scenario) {
        hotspots.push({
          title: poi.coordinates.length > 1 || !state.showPoiLabels ? "" : poi.title,
          slug: `${poi.slug}-${i}`,
          type: poi.hotspotType === "point" ? undefined : poi.hotspotType,
          coordinates: poi.coordinates,
          excerpt: `<h2>${poi.title}</h3>${poi.excerpt}`,
          icon: getPoiIcon(poi.type as MarkerTypes, isPointHotspot(poi) ? poi.color : undefined),
          onClick: () => {
            setTimeout(
              () =>
                krpano
                  ?.get("hotspot")
                  .getArray()
                  .filter((h: any) => new RegExp(`${poi.slug}-\\d+-badge`).test(h.name))
                  .forEach((h: any) => removeHotspot(krpano, h.name)),
              200
            );
            poi.showBadge = false;
            history.push(urlSwitch(poi.type as MarkerTypes, poi.slug));
          },
          border: isPolygonHotspot(poi) || isPolylineHotspot(poi) ? poi.border : undefined,
          fill: isPolygonHotspot(poi) ? poi.fill : undefined,
          allowHighlight: isPolygonHotspot(poi) ? poi.allowHighlight : true,
          showBadge: !isPointHotspot(poi) && poi.showBadge,
        });
      }
    });

    return hotspots;
  }, [pano, panoScenario, urlSwitch, state.showPoiLabels, krpano, history]);

  const faqs = useMemo(
    () =>
      state.faqs
        .filter(isPanoFaq)
        .filter(
          (f) => f.scenario.slug === panoScenario?.slug && f.data.pano.slug === routeState.panoSlug
        ),
    [state.faqs, routeState.panoSlug, panoScenario?.slug]
  );

  const faqHotspots = useMemo(() => {
    const hotspots: Hotspot[] = [];

    faqs.forEach((faq, i) => {
      hotspots.push({
        title: state.showPoiLabels ? "" : faq.question,
        slug: `${faq.slug}-${i}`,
        type: "faq",
        coordinates: [faq.data.location],
        excerpt: `<h2>${faq.question}</h3>${faq.answer}`,
        icon: getPoiIcon(MarkerTypes.Faq),
        url: urlSwitch(MarkerTypes.Faq, faq.slug),
        allowHighlight: false,
      });
    });

    return hotspots;
  }, [faqs, urlSwitch, state.showPoiLabels]);

  const actionUrlSwitch = useCallback(
    (actionHotspot: PanoAction<keyof PanoActionTypeMap, keyof PanoHotspotTypeMap>) => {
      if (!krpano) return;

      krpano.set("is_hs_click", true);
      if (isSwitchScenarioHotspot(actionHotspot)) {
        setPanoState(krpano.get("view"));
        setSwitchScenario(actionHotspot.target.slug);
      } else if (isOpenUrlHotspot(actionHotspot)) {
        if (isLocalUrl(actionHotspot.targetUrl)) {
          history.push(new URL(actionHotspot.targetUrl, window.origin).pathname);
        } else {
          window.open(actionHotspot.targetUrl, "_blank", "noopener,noreferrer");
        }
      }
    },
    [setPanoState, setSwitchScenario, krpano, history]
  );

  const actionHotspots = useMemo(() => {
    const hotspots: (Hotspot & { action: keyof PanoActionTypeMap })[] = [];

    panoScenario?.actions.forEach((action) => {
      const scenario = pano?.scenarios.find((s) => s.slug === panoScenario?.slug);

      if (pano && scenario) {
        hotspots.push({
          title: action.label,
          slug: action.slug,
          type: action.type === "point" ? undefined : action.type,
          coordinates: action.coordinates,
          // TODO: define a default marker for actions
          icon: getPoiIcon(MarkerTypes.Edit, "--color-informative-50"),
          action: action.action,
          onClick: () => actionUrlSwitch(action),
          border: isPolygonHotspot(action) || isPolylineHotspot(action) ? action.border : undefined,
          fill: isPolygonHotspot(action) ? action.fill : undefined,
          allowHighlight: isPolygonHotspot(action) ? action.allowHighlight : true,
        });
      }
    });

    return hotspots;
  }, [pano, panoScenario, actionUrlSwitch]);

  const hotspots = useMemo(
    () => [...panoLinkHotspots, ...poiHotspots, ...faqHotspots, ...actionHotspots],
    [panoLinkHotspots, poiHotspots, faqHotspots, actionHotspots]
  );

  const vrHotspots = useMemo(
    () => [
      ...panoLinkHotspots,
      ...actionHotspots.filter((h) => ["switch-scenario"].includes(h.action)),
      ...poiHotspots,
    ],
    [panoLinkHotspots, actionHotspots, poiHotspots]
  );

  // Keep vertical orientation while in VR
  const vlookat = useMemo(() => {
    if (krpano?.get("webvr.isenabled")) return krpano.get("view.vlookat");
    return participationState.panoLookat?.pitch ?? panoState?.vlookat ?? pano?.pitch ?? 0;
  }, [pano?.pitch, panoState, krpano, participationState.panoLookat?.pitch]);

  const hlookat = useMemo(
    () => participationState.panoLookat?.yaw ?? panoState?.hlookat ?? pano?.yaw ?? 0,
    [participationState.panoLookat, panoState, pano?.yaw]
  );

  const fov = useMemo(
    () => participationState.panoLookat?.fov ?? panoState?.fov ?? pano?.fov ?? 70,
    [participationState.panoLookat, panoState, pano?.fov]
  );

  const zoomIn = useCallback(() => {
    krpano?.call(
      "tween(view.fov, " + krpano.get("view.fov") / krpanoZoomFactor + ", 0.5, easeInOutQuad )"
    );
  }, [krpano]);

  const zoomOut = useCallback(() => {
    krpano?.call(
      "tween(view.fov, " + krpano.get("view.fov") * krpanoZoomFactor + ", 0.5, easeInOutQuad )"
    );
  }, [krpano]);

  if (
    !pano ||
    !panoScenario ||
    (interfaceState.currentEntrance.type !== "map" && loadedPano === undefined)
  ) {
    return null;
  }

  // @TODO Implement fade in and fade out effect
  return (
    <PanoContainer
      className={classNames({
        participating: participating,
        full: interfaceState.currentEntrance.type !== "map",
        vr: isInVr,
      })}
    >
      {!participating &&
        (interfaceState.currentEntrance.type === "map" || panoScenario.isVideo) &&
        !isInVr && (
          <PanoCloseLink
            to={{ pathname: mapUrl(), state: { latestPanoSlug: pano.slug } }}
            onClick={(e) => {
              if (pano!.returnAtEndOfVideo && shouldGoBack.current) {
                history.goBack();
                e.preventDefault();
              }
            }}
          >
            <CloseIconSpan>
              <CloseIcon />
            </CloseIconSpan>
          </PanoCloseLink>
        )}
      <Suspense
        fallback={
          <SkeletonContainer>
            {/** @ts-ignore */}
            <Skeleton
              circle
              width={200}
              height={200}
              style={{
                backgroundColor: "var(--color-neutral-50)",
                backgroundImage:
                  "linear-gradient( 90deg, var(--color-neutral-50),var(--color-neutral-70),var(--color-neutral-50) )",
              }}
            />
          </SkeletonContainer>
        }
      >
        <Krpano
          showFade={showFade}
          fade={[faded, setFaded] as UseStateReturn<boolean>}
          hlookat={hlookat}
          vlookat={vlookat}
          fov={fov}
          onViewChange={setPanoState}
          pano={pano}
          scenario={panoScenario}
          hotspots={hotspots}
          vrHotspots={vrHotspots}
          isVideo={panoScenario.isVideo}
          showVideoControls={pano.showVideoControls}
          returnAtEndOfVideo={pano.returnAtEndOfVideo}
          teasing={teasing}
          setTeasing={setTeasing}
          setSwitchScenario={setSwitchScenario}
          xml={
            panoScenario.isVideo
              ? videoPanoXml(pano, panoScenario, hotspots, state.panoramaFolder, intl)
              : imagePanoXml(pano, panoScenario, hotspots, state.panoramaFolder, intl)
          }
        >
          {(krpano) => (
            <>
              {!participating && (
                <>
                  {!urlState.embed && !isInVr && (
                    <PanoActions alignRight={interfaceState.currentEntrance.type !== "map"}>
                      {!editing ? (
                        <ActionList>
                          {shareAvailable && (
                            <>
                              <Action
                                icon={<ShareIcon />}
                                onClick={() => {
                                  if (
                                    !navigator.platform.includes("Win") &&
                                    navigator.share !== undefined
                                  ) {
                                    shareView(
                                      state,
                                      intl.formatMessage(messages.copyShareLinkSuccessMessage),
                                      intl.formatMessage(messages.copyShareLinkFailedMessage)
                                    );
                                  } else {
                                    setShareModalOpen(true);
                                  }
                                }}
                              >
                                <FormattedMessage {...messages.shareButton} />
                              </Action>
                              <ShareModal
                                open={shareModalOpen}
                                onClose={() => setShareModalOpen(false)}
                              />
                            </>
                          )}
                          {askQuestionAvailable &&
                            state.qaSurveyId &&
                            !panoScenario?.isVideo &&
                            !urlState.embed && (
                              <Action
                                icon={<BubbleIcon />}
                                onClick={() => {
                                  history.push(questionUrl(true));
                                }}
                              >
                                <FormattedMessage {...messages.askQuestionButton} />
                              </Action>
                            )}
                          {vrAvailable && !krpano.get("device.iphone") && (
                            <Action
                              icon={<VrIcon />}
                              onClick={() => krpano.call(`webvr.entervr()`)}
                            >
                              <FormattedMessage
                                id="pano.enter-vr"
                                defaultMessage="View in VR"
                                description="The label for the button which activates VR mode."
                              />
                            </Action>
                          )}
                          {interfaceState.currentEntrance.type !== "map" &&
                            state.map.layerGroups.length && (
                              <IsMobile>
                                <Action icon={<MapIcon />} onClick={() => history.push(mapUrl())}>
                                  <FormattedMessage
                                    id="pano.view-map-button"
                                    defaultMessage="View the map"
                                    description="The label for the button which navigates to the map."
                                  />
                                </Action>
                              </IsMobile>
                            )}
                          {canEditSep && state.panos.length > 1 && (
                            <Action icon={<TourIcon />} onClick={() => setEditing(true)}>
                              <FormattedMessage
                                id="editor.pano.start-editing-button"
                                defaultMessage="Edit panorama"
                                description="The label for the button which activates Pano-edit mode."
                              />
                            </Action>
                          )}
                        </ActionList>
                      ) : (
                        <ActionList>
                          <Action icon={<TourIcon />} onClick={() => setEditing(false)}>
                            <FormattedMessage
                              id="editor.pano.stop-editing-button"
                              defaultMessage="Stop editing"
                              description="The label for the button which deactivates Pano-edit mode."
                            />
                          </Action>
                        </ActionList>
                      )}
                    </PanoActions>
                  )}
                  {!isInVr && (
                    <>
                      <PanoScenarioSwitch pano={pano!} teasing={teasing} />
                      <IsDesktop>
                        <ControlContainer
                          className={classNames("mapboxgl-ctrl mapboxgl-ctrl-group", {
                            minimap:
                              state.minimapEnabled &&
                              pano?.latitude !== undefined &&
                              pano?.longitude !== undefined,
                          })}
                        >
                          <button
                            className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in"
                            onClick={zoomIn}
                            onKeyDown={makeKeyDownHandler(zoomIn)}
                            aria-label={intl.formatMessage({
                              id: "pano.zoom-in",
                              defaultMessage: "Zoom in",
                              description: "Label for the 'zoom in' button in a panorama.",
                            })}
                          >
                            <span className="mapboxgl-ctrl-icon" aria-hidden="true"></span>
                          </button>
                          <button
                            className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out"
                            onClick={zoomOut}
                            onKeyDown={makeKeyDownHandler(zoomOut)}
                            aria-label={intl.formatMessage({
                              id: "pano.zoom-out",
                              defaultMessage: "Zoom out",
                              description: "Label for the 'zoom out' button in a panorama.",
                            })}
                          >
                            <span className="mapboxgl-ctrl-icon" aria-hidden="true"></span>
                          </button>
                        </ControlContainer>
                        {state.minimapEnabled && <MiniMap />}
                      </IsDesktop>
                    </>
                  )}
                </>
              )}
              {!isInVr && krpano && (
                <>
                  {!!panoScenario?.isVideo && (
                    <PanoVideoControls
                      krpano={krpano}
                      showVideoControls={pano!.showVideoControls}
                    />
                  )}
                  {!!pano?.audio && (
                    <PanoAudioControls
                      krpano={krpano}
                      audioName={pano.slug}
                      url={pano.audio}
                      loop={pano.repeatAudio}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Krpano>
      </Suspense>
      {pano.excerpt && (
        <PanoExcerpt>
          <MemoizedWordpressContentWrapper content={pano.excerpt} />
        </PanoExcerpt>
      )}
      <Route path={participationState.layerSlug ? "*" : "/pano/*/map"}>
        <PanoMapWrapper>
          <PanoMap />
          <PanoCloseLink
            to={{ pathname: panoUrl(pano.slug), state: { latestPanoSlug: pano.slug } }}
          >
            <CloseIconSpan>
              <CloseIcon />
            </CloseIconSpan>
          </PanoCloseLink>
        </PanoMapWrapper>
      </Route>
      <Route path="*/participation/:participationItemSlug">
        {participationState.layerSlug && (
          <PanoMapWrapper>
            <PanoMap />
            <PanoCloseLink
              to={{ pathname: panoUrl(pano.slug), state: { latestPanoSlug: pano.slug } }}
            >
              <CloseIconSpan>
                <CloseIcon />
              </CloseIconSpan>
            </PanoCloseLink>
          </PanoMapWrapper>
        )}
      </Route>
      {showFade && (
        <PanoFade>
          <div>{state.name}</div>
        </PanoFade>
      )}
    </PanoContainer>
  );
}

const ControlContainer = styled.div`
  position: absolute;
  right: var(--space-default);
  bottom: var(--space-default);
  z-index: 25;

  .show-video-controls &,
  .show-audio-timeline & {
    margin-bottom: 48px;
  }

  &.minimap {
    bottom: calc(140px + var(--space-default) * 2);
  }
`;
