import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useAppState } from "../AppContext";
import { useRouteState } from "../hooks/useRouteState";
import {
  isElementQuestion,
  isFileUploadQuestion,
  isLocationQuestion,
  isTextQuestion,
  useSurveyState,
} from "../SurveyContext";
import { breakpoints } from "../utils/styles";
import { ElementQuestion } from "./ElementQuestion";
import { PlacePinQuestion } from "./PlacePinQuestion";
import { SurveyHeader } from "./SurveyHeader";
import { SurveyNavigation } from "./SurveyNavigation";
import arrow from "../images/singleArrowLeft.svg";
import { OpenQuestion } from "./OpenQuestion";
import { FileUploadQuestion } from "./FileUploadQuestion";
import { useIntl } from "react-intl";
import { messages } from "../global-intl-messages";
import { SidebarFocusBackLink } from "../components/Sidebar/SidebarFocusStyles";
import { useParticipationItemUrl } from "../hooks/useParticipationItemUrl";

export const SurveyContainer = () => {
  const { surveyState, error, setError } = useSurveyState();
  const { state } = useAppState();
  const routeState = useRouteState();
  const { survey, activePinId } = surveyState;
  const [minimized, setMinimized] = useState(false);
  const participationItemUrl = useParticipationItemUrl();
  const intl = useIntl();

  const isBigScreen = matchMedia(breakpoints[10]).matches;

  const shouldMinimize = useMemo(() => {
    return (activePinId !== undefined || minimized) && !isBigScreen;
  }, [activePinId, isBigScreen, minimized]);

  const currentParticipationItem = useMemo(
    () => state.participation.find((p) => p.slug === routeState.participationItemSlug),
    [state.participation, routeState.participationItemSlug]
  );

  const isTimSurvey = useMemo(
    () => currentParticipationItem?.surveyId?.startsWith("tim.survey://"),
    [currentParticipationItem?.surveyId]
  );

  const activePage = useMemo(
    () => survey?.pages.find((p) => p.id === surveyState.activePageId),
    [survey, surveyState.activePageId]
  );

  const activeElements = useMemo(
    () =>
      activePage?.elements.map((element) => {
        if (isLocationQuestion(element))
          return <PlacePinQuestion key={element.id} question={element} />;
        if (isTextQuestion(element)) return <OpenQuestion key={element.id} question={element} />;
        if (isFileUploadQuestion(element))
          return <FileUploadQuestion key={element.id} question={element} />;
        if (isElementQuestion(element))
          return <ElementQuestion key={element.id} question={element} />;
        return null;
      }) ?? null,
    [activePage?.elements]
  );

  if (error) {
    return <StyledSurveyContainer id="survey-el">
      <StyledHeader>
        <SidebarFocusBackLink
          to={participationItemUrl()}
          label={intl.formatMessage(messages.backButton)}
          onClick={() => setError(undefined)}
        />
        <h2>{error.title}</h2>
      </StyledHeader>
      <SurveyContent>
        {error.message}
      </SurveyContent>
    </StyledSurveyContainer>
  }

  if (!survey || !activePage || !isTimSurvey) return null;

  return (
    <StyledSurveyContainer id="survey-el" className={shouldMinimize ? "minimized" : undefined}>
      <SurveyHeader />
      <SurveyContent>
        <h2>{activePage.title}</h2>
        <p>{activePage.description}</p>
        {activeElements}
      </SurveyContent>
      <SurveyNavigation />
      {!isBigScreen && (
        <MinimizeButton
          onClick={() => setMinimized(!minimized)}
          className={shouldMinimize ? "minimized" : undefined}
        />
      )}
    </StyledSurveyContainer>
  );
};

const StyledSurveyContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 29rem;
  height: auto;
  top: 0.5rem;
  left: 0.5rem;
  background: white;
  border-radius: 0.25rem;

  z-index: 100;
  max-height: calc(100% - 1rem);
  overflow: hidden;
  transition: transform 0.15s ease-in-out;

  &.minimized {
    transform: translateX(calc(-100% + 2rem));
  }

  @media (max-width: 600px) {
    width: 100vw;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
  }
`;

const MinimizeButton = styled.div`
  position: absolute;
  width: 2rem;
  height: 4rem;
  background: var(--color-neutral-10) url(${arrow}) center center no-repeat;
  right: 0;
  top: calc(50% - 2rem);
  border-radius: 5px 0 0 5px;

  &.minimized {
    transform: scaleX(-1);
    border-radius: 0 5px 5px 0;
  }
`;

const SurveyContent = styled.div`
  height: auto;
  padding: 1rem 2rem 1rem 1rem;
  overflow-y: auto;

  @media (max-width: 600px) {
    flex: 1;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: var(--color-neutral-10);
  border-radius: 0.25rem 0.25rem 0 0;
`;
