import "mapbox-gl/dist/mapbox-gl.css";
import BaseStyles from "./BaseStyles";
import TokenStyles from "./TokenStyles";

import React from "react";
import { StateProvider } from "./AppContext";
import { BrowserRouter as Router } from "react-router-dom";
import AppContent from "./AppContent";
import { InterfaceStateProvider } from "./InterfaceContext";
import { EditStateProvider } from "./EditContext";
import { SurveyStateProvider } from "./SurveyContext";
import { DebugContextProvider } from "./DebugContext";

function App() {
  // TODO: figure out how to combine this with survey
  // const [tabUser, setTabUser] = useState(false);

  // useEffect(() => {
  // window.addEventListener("keydown", (e) => {
  //   if (e.key === "Tab") {
  //     setTabUser(true);
  //   }
  // });
  // window.addEventListener("mousedown", () => setTabUser(false));
  // }, []);

  return (
    <>
      <TokenStyles />
      <BaseStyles />
      <Router>
        <DebugContextProvider>
          <StateProvider>
            <SurveyStateProvider>
              <InterfaceStateProvider>
                <EditStateProvider>
                  <AppContent />
                </EditStateProvider>
              </InterfaceStateProvider>
            </SurveyStateProvider>
          </StateProvider>
        </DebugContextProvider>
      </Router>
    </>
  );
}

export default App;
