import { useMemo } from "react";

export function useFeature(requiredFeature: Feature) {
  const featuresInput = document.querySelector<HTMLInputElement>("body>[name=_wpftrs]");

  const result = useMemo(() => {
    const features = (featuresInput?.value.split(",") as Feature[]) ?? [];
    return features.includes(requiredFeature);
  }, [requiredFeature, featuresInput]);

  return result;
}

export enum Feature {
  Participation = "participation",
  ParticipationResults = "participation_results",
  Phasing = "phasing",
  Legend = "legend",
  Sharing = "sharing",
  Measuring = "measuring",
  VR = "vr",
  AskQuestion = "ask_question",
  Faqs = "faqs",
  Debug = "debugging",
}
