import { decodeEntities } from "@wordpress/html-entities";
import html2canvas from "html2canvas";
import { IntlShape } from "react-intl";
import { MarkerTypes } from "../shared/components/MarkerImages";
import { WordpressPoi, Poi, LayerGroup, Pano, MapPoi, PoiTypes } from "../types";
import { upsert } from "./areaUtils";

function transformWordpressPoi(
  data: WordpressPoi,
  layerGroups: LayerGroup[],
  panos: Pano[],
  lastVisit?: Date | null,
): Poi {
  const latitude = Number(data.acf.map_location?.latitude) || undefined;
  const longitude = Number(data.acf.map_location?.longitude) || undefined;
  const scenarios = data.acf.scenarios || [];
  const panoLocations = data.acf.panorama_location;
  const image = data.acf.image;
  const showBadge = !!lastVisit && !!data.acf.allow_badge && new Date(data.modified) > lastVisit;

  if (data.acf.map_location && latitude !== undefined && longitude !== undefined) {
    const layers = data.acf.map_location.layers || [];

    // Add pois to scenario's in the map
    layerGroups
      .filter((l) => layers.includes(l.id))
      .forEach((layerGroup) => {
        layerGroup.scenarios
          .filter((s) => scenarios.includes(s.id))
          .forEach((scenario) => {
            upsert(["slug", "type"], scenario.pois, {
              slug: data.slug || `draft-${data.id}`,
              latitude: latitude,
              longitude: longitude,
              title: decodeEntities(data.title.rendered),
              type: data.acf.type,
              color: data.acf.map_location!.color,
            });
          });
      });
  }

  if (panoLocations && panos) {
    panoLocations.forEach((panoLocation) => {
      const pano = panos.find((pano) => pano.id === panoLocation.panorama);

      if (!pano) return;

      const panoScenarios = pano.scenarios.filter((scenario) => scenarios.includes(scenario.id));

      if (panoScenarios.length) {
        const base = {
          type: data.acf.type,
          slug: data.slug || `draft-${data.id}`,
          excerpt: data.excerpt.rendered,
          title: decodeEntities(data.title.rendered),
          showBadge,
        };
        if (!panoLocation.hotspot_type || panoLocation.hotspot_type === "point") {
          panoScenarios.forEach((scenario) => {
            scenario.pois.push({
              ...base,
              hotspotType: "point",
              coordinates: [
                {
                  yaw: Number(panoLocation.yaw),
                  pitch: Number(panoLocation.pitch),
                },
              ],
              color: panoLocation.fill_color,
            })
          });
        } else if (
          panoLocation.hotspot_type === "polyline" ||
          panoLocation.hotspot_type === "polygon"
        ) {
          const coordinates = panoLocation.hotspot_coordinates.map((c) => ({
            yaw: Number(c.yaw),
            pitch: Number(c.pitch),
          }));

          const border = {
            color: panoLocation.border_color || "--color-black",
            opacity:
              panoLocation.border_color && panoLocation.hasOwnProperty("border_opacity")
                ? Number(panoLocation.border_opacity)
                : Number(!!panoLocation.border_color),
            width: panoLocation.hasOwnProperty("border_width")
              ? Number(panoLocation.border_width)
              : 1,
          };

          if (panoLocation.hotspot_type === "polyline") {
            panoScenarios.forEach((scenario) => {
              scenario.pois.push({
                ...base,
                coordinates,
                border,
                hotspotType: "polyline",
              });
            });
          } else {
            panoScenarios.forEach((scenario) => {
              scenario.pois.push({
                ...base,
                coordinates,
                border,
                hotspotType: "polygon",
                fill: {
                  color: panoLocation.hasOwnProperty("fill_color")
                    ? panoLocation.fill_color
                    : "--color-white",
                  opacity: panoLocation.hasOwnProperty("fill_opacity")
                    ? Number(panoLocation.fill_opacity)
                    : 0,
                },
                allowHighlight: true
              });
            });
          }
        }
      }
    });
  }

  return {
    id: data.id,
    latitude,
    longitude,
    title: decodeEntities(data.title.rendered),
    content: data.content.rendered,
    excerpt: data.excerpt.rendered,
    image,
    slug: data.slug || `draft-${data.id}`,
    featuredMedia: data.featured_media,
    type: data.acf.type,
    color: data.acf.map_location?.color,
    status: data.status,
    showBadge,
  };
}

export function transformWordpressPois(
  data: WordpressPoi[],
  layerGroups: LayerGroup[],
  panos: Pano[],
  lastVisit?: Date | null,
) {
  return data.map((poi) => transformWordpressPoi(poi, layerGroups, panos, lastVisit));
}

export const generateLabelsGeoJson = (pois: MapPoi[]): GeoJSON.FeatureCollection => ({
  type: "FeatureCollection",
  features: pois.map((poi) => ({
    type: "Feature",
    properties: {
      title: poi.type === "question" ? "Denk mee" : poi.title,
      slug: poi.slug,
      type: poi.type,
      description: poi.type === "question" ? poi.title : "",
    },
    geometry: {
      type: "Point",
      coordinates: [poi.longitude, poi.latitude],
    },
  })),
});

const labelTranslations: { [key in PoiTypes]: [number, number] } = {
  pano: [5, 5],
  info: [0, -17],
  default: [0, -17],
  question: [0, -17],
  video: [5, 5],
  faq: [5, 5],
  edit: [5, 5],
  vcr: [5, 5],
};

const translatePoint = (point: [number, number], type: MarkerTypes) => {
  return point.map((n, i) => n - labelTranslations[type][i]) as [number, number];
};

export const poiLabelLayer = (type: MarkerTypes) => {
  let filter = ["in", ["get", "type"], ["literal", ["info", "default", "question"]]];
  let textRadialOffset = 1.3;
  let id = "poiLabelLayer";
  if (type === MarkerTypes.Pano) {
    id = "panoLabelLayer";
    filter = ["in", ["get", "type"], ["literal", ["pano"]]];
    textRadialOffset = 1.5;
  }
  return {
    id: id,
    type: "symbol",
    source: "labels",
    filter,
    layout: {
      "text-field": [
        "format",
        ["get", "title"],
        { "font-scale": 1 },
        "\n",
        {},
        ["get", "description"],
        {
          "font-scale": 0.8,
          "text-font": ["literal", ["Roboto Medium"]],
        },
      ],
      "text-variable-anchor": ["left", "right"],
      "text-size": 15,
      "text-radial-offset": textRadialOffset,
      "text-justify": "auto",
      "text-font": ["Roboto Medium"],
      // 'text-optional': true,
      // "icon-image": ["concat", ["get", "icon"], "-15"],
      // "icon-allow-overlap": true,
    },
    paint: {
      "text-color": "#FFFFFF",
      "text-translate": labelTranslations[type],
      "text-halo-color": "#191825",
      "text-halo-width": 1.25,
      "text-halo-blur": 0.625,
    },
  };
};

export const getLabelsOnPoint = (point: [number, number], map: mapboxgl.Map) => {
  const poiLabels = map.getLayer("poiLabelLayer")
    ? map.queryRenderedFeatures(translatePoint(point, MarkerTypes.Default), {
        layers: ["poiLabelLayer"],
      })
    : [];
  const panoLabels = map.getLayer("panoLabelLayer")
    ? map.queryRenderedFeatures(translatePoint(point, MarkerTypes.Pano), {
        layers: ["panoLabelLayer"],
      })
    : [];
  return Array.from(new Set([...poiLabels, ...panoLabels]));
};

interface MediaData {
  url: string;
  width: number;
  height: number;
  top: number;
  left: number;
}

/** @TODO - determine what to do with the returned video and frame data,  */
export const toHotspot = async (
  container: HTMLElement,
  intl: IntlShape
): Promise<{ objectUrl: string; videoData: MediaData[]; frameData: MediaData[] }> => {
  const frameData: MediaData[] = [];
  const videoData: MediaData[] = [];

  await Promise.all(
    Array.from(
      container.querySelectorAll("img"),
      (img) =>
        new Promise<string>((resolve) => {
          if (img.complete) {
            resolve(img.currentSrc);
          } else {
            const resolver = () => {
              img.removeEventListener("load", resolver);
              resolve(img.currentSrc);
            };
            img.addEventListener("load", resolver);
            img.setAttribute("loading", "eager");
          }
        })
    )
  );

  const canvas = await html2canvas(container, {
    useCORS: true,
    removeContainer: true,
    windowHeight: 1080,
    windowWidth: 1920,
    scale: 1,
    logging: process.env.NODE_ENV === "development",
    backgroundColor: null,
    onclone: async (clonedDoc, clonedRoot) => {
      const videoElements = Array.from(clonedRoot.querySelectorAll("video"));
      const frameElements = Array.from(clonedRoot.querySelectorAll("iframe"));

      const unsupportedDataReplacement = clonedDoc.createElement("blockquote");
      unsupportedDataReplacement.classList.add("vr-warning");
      unsupportedDataReplacement.textContent = intl.formatMessage({
        id: "vr.unsupported-content",
        defaultMessage:
          "This section contains content which isn't supported in VR, please view it outside of VR.",
        description: "Message shown in place of unsupported VR content.",
      });

      // TODO: investigate if iframes can serve any purpose at all in VR
      frameElements.forEach((iframe) => {
        iframe.replaceWith(unsupportedDataReplacement.cloneNode(true) as HTMLQuoteElement);

        frameData.push({
          url: iframe.src,
          width: iframe.offsetWidth,
          height: iframe.offsetHeight,
          top: iframe.offsetTop,
          left: iframe.offsetLeft,
        });
      });

      // TODO: play (inline) videos in VR, determine where and how to place their playback controls
      videoElements.forEach((video) => {
        const url =
          video.srcObject instanceof Blob ? URL.createObjectURL(video.srcObject) : video.currentSrc;

        video.replaceWith(unsupportedDataReplacement.cloneNode(true) as HTMLQuoteElement);

        if (url) {
          videoData.push({
            url: url,
            width: video.offsetWidth,
            height: video.offsetHeight,
            top: video.offsetTop,
            left: video.offsetLeft,
          });
        }
      });

      clonedRoot.classList.add("rendering-vr");
    },
  });

  const objectUrl = await new Promise<string>((resolve, reject) => {
    canvas.toBlob((blob) => (blob ? resolve(URL.createObjectURL(blob)) : reject()), "image/png");
  });

  return {
    objectUrl,
    videoData,
    frameData,
  };
};
