import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../../AppContext";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { Area, MenuItem } from "../../types";
import { makeKeyDownHandler } from "../../utils/a11y-utils";
import { breakpoints, sidebarWidth } from "../../utils/styles";
import { MenuItemIntroduction, MenuItemTitle, StyledMenuItem } from "./MenuItemStyles";
import { ReactComponent as LinkArrowRight } from "../../images/linkArrowRight.svg";
import { useMapState } from "../../hooks/useMapState";
import { useSetMapState } from "../../hooks/useSetMapState";
import { useContentUrl } from "../../hooks/useContentUrl";
import { messages } from "../../global-intl-messages";
import { FormattedMessage } from "react-intl";
import WebMercatorViewport from "viewport-mercator-project";

export default function Areas({ menuItem }: { menuItem: MenuItem }) {
  const { state } = useAppState();
  const setMapState = useSetMapState();
  const mapState = useMapState();
  const contentUrl = useContentUrl();

  const navigate = (e: React.KeyboardEvent | React.MouseEvent, area: Area) => {
    const padding = {
      left: matchMedia(breakpoints[30]).matches ? sidebarWidth : 0,
      right: 0,
      top: 0,
      bottom: 0,
    };
    const { innerWidth, innerHeight } = window;
    const viewport = new WebMercatorViewport({
      width: innerWidth,
      height: innerHeight,
    }).fitBounds(area.boundingBox, {
      padding,
    });
    const zoomlevel =
      viewport.zoom < state.mapConfig.areaInflectionPoint
        ? state.mapConfig.areaInflectionPoint
        : viewport.zoom;
    setMapState(viewport.latitude, viewport.longitude, zoomlevel);
    e.preventDefault();
  };

  const areaListItems = useMemo(() => {
    const shown = state.areas.filter((area) =>
      menuItem.children?.length ? menuItem.children.some((c) => c.page === area.id) : true
    );
    return shown.sort((areaA, areaB) => {
      if (!menuItem.children?.length) return 0;

      const indexA = menuItem.children.findIndex((c) => c.page === areaA.id);
      const indexB = menuItem.children.findIndex((c) => c.page === areaB.id);

      return indexA - indexB;
    });
  }, [menuItem, state.areas]);

  return (
    <div>
      {areaListItems.map((area) => {
        const selected = mapState && isSelected(area.boundingBox, mapState);
        return (
          <StyledMenuItem key={area.slug} className={selected ? "active" : ""}>
            <div
              onClick={(e) => navigate(e, area)}
              onKeyDown={makeKeyDownHandler((e) => navigate(e, area))}
              role="button"
              tabIndex={0}
            >
              <MenuItemTitle>{area.title}</MenuItemTitle>
              {area.excerpt && (
                <MenuItemIntroduction>
                  <MemoizedWordpressContentWrapper content={area.excerpt} />
                </MenuItemIntroduction>
              )}
            </div>
            {area.excerpt && area.content && (
              <p className="link">
                <Link to={contentUrl(area.slug)}>
                  <FormattedMessage {...messages.readMore} />{" "}
                  <span className="icon">
                    <LinkArrowRight />
                  </span>
                </Link>
              </p>
            )}
          </StyledMenuItem>
        );
      })}
    </div>
  );
}

const isSelected = (
  bbox: [[number, number], [number, number]],
  mapState: { lat: number; long: number }
) => {
  const longs = [bbox[0][0], bbox[1][0]];
  const lats = [bbox[0][1], bbox[1][1]];

  return (
    mapState.long >= Math.min(...longs) &&
    mapState.long <= Math.max(...longs) &&
    mapState.lat >= Math.min(...lats) &&
    mapState.lat <= Math.max(...lats)
  );
};
