import { breakpoints } from "../../utils/styles";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const projectHiddenState = css`
  opacity: 0;
  transform: scale(0.95);

  @media ${breakpoints[30]} {
    transform: translateX(-16px) scale(1);
    &.no-sidebar {
      transform: translateX(calc((-1 * var(--sidebar-width) + 10px) - 16px)) scale(1);
    }
  }
`;

const projectVisibleState = css`
  opacity: 1;
  transform: translateX(0);
  @media ${breakpoints[30]} {
    &.no-sidebar {
      transform: translateX(calc(-1 * var(--sidebar-width) + 10px)) scale(1);
    }
  }
`;

export const StyledProject = styled.div`
  grid-column: 1 / 2;
  z-index: 30;
  grid-row: 2 / 3;

  @media ${breakpoints[30]} {
    grid-column: 2 / 4;
    box-shadow: 0 0 0;
    background: var(--color-white);
    z-index: 10;
  }

  background: var(--color-neutral-10);
  box-shadow: var(--box-shadow-30);

  transition: transform 300ms var(--bezier-a), opacity 200ms var(--bezier-a);
  pointer-events: auto;

  ${projectVisibleState}

  &.animate-enter {
    ${projectHiddenState}
  }

  &.animate-enter-active,
  &.animate-exit {
    ${projectVisibleState}
  }

  &.animate-exit-active {
    ${projectHiddenState}
  }
`;

export const ProjectScroll = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  padding: 16px;

  @media ${breakpoints[30]} {
    padding: 44px;
  }
`;

export const ProjectBody = styled.div`
  max-width: 70ch;
  width: 100%;
  margin: auto;

  figure {
    margin: var(--space-stack-default);
    img {
      max-width: 100%;
      border-radius: var(--border-radius-20);

      + figcaption {
        color: var(--color-neutral-50);
        margin-top: var(--space-10);
        font-weight: 500;
        font-size: var(--font-size-20);
      }
    }
  }
`;

export const PhaseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--space-40);
`;

export const PhaseLink = styled(Link)`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--color-neutral-70);
  text-align: center;
  line-height: 48px;
  text-decoration: none;
  font-weight: 600;
  color: var(--color-neutral-90);

  &:hover {
    background-color: var(--color-neutral-10);
  }

  &.active {
    border-color: var(--color-primary-50);
    background-color: var(--color-primary-50);
    color: white;
  }
`;

export const DateDisplay = styled.p`
  text-align: right;
  font-weight: 600;
  color: var(--color-neutral-50);
`;
