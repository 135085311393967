/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateAnswerModel {
  /**
   * @format int32
   * @min 1
   */
  questionId: number;
  type: string;
}

export type CreateFileUploadAnswerModel = CreateAnswerModel & { amountOfFiles: number; type: string };

export type CreateLocationPickerAnswerModel = CreateAnswerModel & { pins: PinModel[]; type: string };

export interface CreateResponseModel {
  answers: CreateAnswerModel[];
}

export type CreateTextAnswerModel = CreateAnswerModel & { text: string; type: string };

export interface ElementListItemModel {
  type: string;

  /** @format int32 */
  id: number;
}

export type ElementModel = ElementListItemModel & { title: string; content: string; type: string };

export interface FileUploadEndpointReferenceModel {
  /** @format int32 */
  answerId: number;

  /** @format int32 */
  questionId: number;
}

export type FileUploadQuestionModel = QuestionModel & {
  maxAmount: number;
  maxFileSize: number;
  allowedTypes: string[];
  type: string;
};

export type GeoLocationPinModel = PinModel & { layerId: number; type: string };

export type LocationPickerQuestionModel = QuestionModel & {
  pins: PinDefinitionModel[];
  type: string;
  layer?: number | null;
  panorama?: number | null;
  restrictions: RestrictionLayerModel[];
};

export interface PageListItemModel {
  /** @format int32 */
  id: number;
  title: string;
  description: string;

  /** @format int32 */
  elements: number;
}

export interface PageModel {
  /** @format int32 */
  id: number;
  title: string;
  description: string;
  elements: ElementModel[];
}

export type PanoLocationPinModel = PinModel & { panoId: number; type: string };

export interface PinDefinitionModel {
  /** @format int32 */
  id: number;
  icon: string;
  label: string;
  color?: string | null;

  /** @format int32 */
  minAmount: number;

  /** @format int32 */
  maxAmount?: number | null;
  allowComment: boolean;
}

export interface PinModel {
  /** @format int32 */
  pinDefinition: number;

  /**
   * @format double
   * @min -90
   * @max 90
   */
  latitude: number;

  /**
   * @format double
   * @min -180
   * @max 180
   */
  longitude: number;

  /** @format int32 */
  scenarioId: number;
  comment: string;
  type: string;
}

export type QuestionModel = ElementModel & { required: boolean };

export interface QuestionnaireListItemModel {
  /** @format int32 */
  id: number;
  name: string;
  state: SurveyState;
  questionnaireType: QuestionnaireType;
}

export interface QuestionnaireModel {
  /** @format int32 */
  id: number;
  name: string;
  singleEntryPolicy: boolean;
  state: SurveyState;
  pages: PageModel[];
  questionnaireType: QuestionnaireType;
}

/**
 * @format int32
 */
export type QuestionnaireType = 0 | 1;

export interface RestrictionLayerModel {
  pins: number[];
  cluster: string[];
  restrictionType: RestrictionType;
  message: string;
}

/**
 * @format int32
 */
export type RestrictionType = 0 | 1;

/**
 * @format int32
 */
export type SurveyState = 0 | 1;

export type TextQuestionModel = QuestionModel & { multiline: boolean; maxLength: number; type: string };

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title TIM Survey EndUser API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  surveys = {
    /**
     * No description
     *
     * @tags Elements
     * @name ListQuestionsFromSurvey
     * @request GET:/surveys/{surveyId}/elements/questions
     */
    listQuestionsFromSurvey: (surveyId: number, params: RequestParams = {}) =>
      this.request<ElementModel[], any>({
        path: `/surveys/${surveyId}/elements/questions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name ListElementsFromSurveyPage
     * @request GET:/surveys/{surveyId}/pages/{pageId}/elements
     */
    listElementsFromSurveyPage: (surveyId: number, pageId: number, params: RequestParams = {}) =>
      this.request<ElementModel[], any>({
        path: `/surveys/${surveyId}/pages/${pageId}/elements`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Elements
     * @name GetElement
     * @request GET:/surveys/{surveyId}/elements/{elementId}
     */
    getElement: (surveyId: number, elementId: number, params: RequestParams = {}) =>
      this.request<ElementModel, any>({
        path: `/surveys/${surveyId}/elements/${elementId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name ListPages
     * @request GET:/surveys/{surveyId}/pages
     */
    listPages: (surveyId: number, params: RequestParams = {}) =>
      this.request<PageListItemModel[], any>({
        path: `/surveys/${surveyId}/pages`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetPage
     * @request GET:/surveys/{surveyId}/pages/{pageId}
     */
    getPage: (surveyId: number, pageId: number, params: RequestParams = {}) =>
      this.request<PageModel, any>({
        path: `/surveys/${surveyId}/pages/${pageId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name ListPinDefinitions
     * @request GET:/surveys/{surveyId}/elements/{questionId}/pindefinitions
     */
    listPinDefinitions: (surveyId: number, questionId: number, params: RequestParams = {}) =>
      this.request<PinDefinitionModel[], any>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PinDefinitions
     * @name GetPinDefinition
     * @request GET:/surveys/{surveyId}/elements/{questionId}/pindefinitions/{pinDefinitionId}
     */
    getPinDefinition: (surveyId: number, questionId: number, pinDefinitionId: number, params: RequestParams = {}) =>
      this.request<PinDefinitionModel, any>({
        path: `/surveys/${surveyId}/elements/${questionId}/pindefinitions/${pinDefinitionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Responses
     * @name CreateResponse
     * @request POST:/surveys/{surveyId}/responses
     */
    createResponse: (surveyId: number, data: CreateResponseModel, params: RequestParams = {}) =>
      this.request<FileUploadEndpointReferenceModel[], any>({
        path: `/surveys/${surveyId}/responses`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name ListSurveys
     * @request GET:/surveys
     */
    listSurveys: (params: RequestParams = {}) =>
      this.request<QuestionnaireListItemModel[], any>({
        path: `/surveys`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Surveys
     * @name GetSurvey
     * @summary Retrieves a specific Survey including its Pages and Elements.
     * @request GET:/surveys/{surveyId}
     */
    getSurvey: (surveyId: number, params: RequestParams = {}) =>
      this.request<QuestionnaireModel, void>({
        path: `/surveys/${surveyId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name UploadFile
     * @request POST:/files/{answerId}
     */
    uploadFile: (answerId: number, data: { file?: File }, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/files/${answerId}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
}
