import classNames from "classnames";
import styled from "styled-components";
import { DebugLevel, useDebugContext } from "../../DebugContext";
import { useCapability, Capability } from "../../hooks/useCapability";
import { useFeature, Feature } from "../../hooks/useFeature";
import { getTokenValue } from "../../TokenStyles";
import { makeKeyDownHandler } from "../../utils/a11y-utils";
import { addOpacity } from "../../utils/styles";
import { Content } from "../Sidebar/SidebarStyles";

export const DebugContent = () => {
  const debugContext = useDebugContext();
  const debugEnabled = useFeature(Feature.Debug);
  const debugCap = useCapability(Capability.DebugSep);

  return debugEnabled && debugCap ? (
    <Content>
      <h3>Debug window</h3>
      <p>Show logging output for:</p>
      <ToggleDebugType type={DebugLevel.Errors} />
      <ToggleDebugType type={DebugLevel.Warnings} />
      <ToggleDebugType type={DebugLevel.Info} />
      <ToggleDebugType type={DebugLevel.Debug} />
      <DebugMemoryInput />
      {debugContext.messages.map((debugMessage, index) => {
        const { type, scope, message } = debugMessage;
        const close = () => debugContext.clearMessage(debugMessage);
        return (
          <StyledDebugMessage
            key={index}
            className={classNames({
              debug: type === DebugLevel.Debug,
              info: type === DebugLevel.Info,
              warning: type === DebugLevel.Warnings,
              error: type === DebugLevel.Errors,
            })}
          >
            <span className="inner">
              [{scope}]: {message}{" "}
            </span>
            <span
              className="close"
              role="button"
              onClick={close}
              onKeyDown={makeKeyDownHandler(close)}
            >
              {"\u00d7"}
            </span>
          </StyledDebugMessage>
        );
      })}
    </Content>
  ) : (
    <Content>
      <StyledDebugMessage className="error">
        You're not allowed to debug this platform.
      </StyledDebugMessage>
    </Content>
  );
};

const StyledDebugMessage = styled.div`
  margin: 4px 0;
  font-size: var(--font-size-20);
  line-height: var(--line-height-20);
  border-left: 4px solid;
  display: flex;
  align-items: center;

  &:before {
    width: 1.5em;
    height: 1.5em;
    margin: 4px 8px;
  }

  &.debug {
    color: var(--color-success-50);
    border-color: var(--color-success-50);

    &:before {
      content: "🐛";
      display: block;
    }
  }

  &.info {
    color: var(--color-informative-50);
    border-color: var(--color-informative-50);

    &:before {
      content: "i";
      line-height: 1.5;
      display: block;
      text-align: center;
      background: var(--color-informative-50);
      color: white;
      border-radius: 1em;
    }
  }

  &.warning {
    color: var(--color-error-30);
    border-color: var(--color-error-30);

    &:before {
      content: "⚠️";
      display: block;
    }
  }

  &.error {
    color: var(--color-error-70);
    border-color: var(--color-error-70);

    &:before {
      content: "❌";
      display: block;
    }
  }

  > .inner {
    flex: 2;
    word-break: break-all;
    white-space: pre-wrap;
  }

  > .close {
    cursor: pointer;
    padding: 4px;
  }
`;

const ToggleDebugType = ({ type }: { type: DebugLevel }) => {
  const { debugLevel, toggleDebugType } = useDebugContext();

  return (
    <>
      <StyledToggleDebugType
        className={classNames({
          debug: type === DebugLevel.Debug,
          info: type === DebugLevel.Info,
          warning: type === DebugLevel.Warnings,
          error: type === DebugLevel.Errors,
          active: debugLevel & type,
        })}
        onClick={() => toggleDebugType(type)}
      >
        {DebugLevel[type]}
      </StyledToggleDebugType>
    </>
  );
};

const StyledToggleDebugType = styled.span`
  display: inline-block;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 4px 8px;
  margin: 0 4px 4px 0;
  font-size: var(--font-size-10);

  &.debug {
    background-color: ${addOpacity(getTokenValue("--color-success-50"), 0.1)};

    &.active {
      color: var(--color-success-50);
      border-color: var(--color-success-50);
    }
  }

  &.info {
    background-color: ${addOpacity(getTokenValue("--color-informative-50"), 0.1)};

    &.active {
      color: var(--color-informative-50);
      border-color: var(--color-informative-50);
    }
  }

  &.warning {
    background-color: ${addOpacity(getTokenValue("--color-error-30"), 0.1)};

    &.active {
      color: var(--color-error-30);
      border-color: var(--color-error-30);
    }
  }

  &.error {
    background-color: ${addOpacity(getTokenValue("--color-error-70"), 0.1)};

    &.active {
      color: var(--color-error-70);
      border-color: var(--color-error-70);
    }
  }

  &.active {
    &:after {
      content: "\u00d7";
      margin-left: 4px;
    }
  }
`;

const DebugMemoryInput = () => {
  const { maxLogs, setMaxLogs } = useDebugContext();

  return (
    <StyledDebugMemoryInput>
      <label htmlFor="maxLogs">Maximum logs stored</label>
      <input
        name="maxLogs"
        type="number"
        value={maxLogs}
        min={0}
        onChange={(e) => setMaxLogs(Math.max(e.target.valueAsNumber ?? Infinity, 0))}
      />
    </StyledDebugMemoryInput>
  );
};

const StyledDebugMemoryInput = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  gap: 0.5em;

  & > label {
    white-space: nowrap;
  }

  & > input[type="number"] {
    padding: .5em;
  }
`;
