import { Link } from "react-router-dom";
import { useAppState } from "../../AppContext";
import { useInterfaceState } from "../../InterfaceContext";
import { ParagraphSmall } from "../../shared/components/ParagraphStyles";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
// import { AboutLink } from "./AboutLink";
import Areas from "./Areas";
import InformationPages from "./InformationPages";
import MapLayerGroups from "./MapLayerGroups";
import PanoList from "./PanoList";
import ParticipationList from "./ParticipationList";
import { Scroll, ScrollInner, Content, SocialMediaButton } from "./SidebarStyles";
import { ToursList } from "./ToursList";
import { ReactComponent as LinkArrowRight } from "../../images/linkArrowRight.svg";
import React, { ReactNode, useCallback, useMemo, useRef } from "react";
import { useUrlState } from "../../hooks/useUrlState";
import { AppState, MenuItem } from "../../types";
import { ReservedMenuIndices } from "../../utils/menuUtils";
import { Timeline } from "../Timeline/Timeline";
import { ReactComponent as FacebookIcon } from "../../images/social-facebook.svg";
import { ReactComponent as TwitterIcon } from "../../images/social-twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../images/social-linkedin.svg";
import { ReactComponent as InstagramIcon } from "../../images/social-instagram.svg";
import { ReactComponent as YoutubeIcon } from "../../images/social-youtube.svg";
import { FormattedMessage } from "react-intl";
import { messages } from "../../global-intl-messages";
import { DebugContent } from "../Content/DebugContent";

export function SidebarBody() {
  const { state } = useAppState();
  const { interfaceState } = useInterfaceState();
  const scrollRef = useRef<any>();
  const urlState = useUrlState();

  const selectedNavigationItem = urlState.selectedNavigationItem
    ? Number(urlState.selectedNavigationItem)
    : ReservedMenuIndices.Home;

  const homepage = useMemo(() => {
    return state.pages?.find((p) => p.slug === "home");
  }, [state.pages]);

  const currentItem: MenuItem | undefined = state.menuItems[selectedNavigationItem];

  const currentPage = useMemo(() => {
    if (!currentItem?.page) return undefined;

    return (
      state.pages.find((p) => p.id === currentItem.page) ||
      state.panos.find((p) => p.id === currentItem.page) ||
      state.tours.find((p) => p.id === currentItem.page) ||
      state.tourPoints.find((p) => p.id === currentItem.page) ||
      // state.projects.find(p => p.id === currentItem.page)
      state.map.layerGroups.find((p) => p.id === currentItem.page) ||
      state.pois.find((p) => p.id === currentItem.page) ||
      state.participation.find((p) => p.id === currentItem.page) ||
      state.areas.find((p) => p.id === currentItem.page) ||
      state.phasing.find((p) => p.id === currentItem.page)
    );
  }, [
    currentItem,
    state.pages,
    state.panos,
    state.tours,
    state.tourPoints,
    state.map,
    state.pois,
    state.participation,
    state.areas,
    state.phasing,
  ]);

  const getIcon = useCallback((key: keyof AppState["socials"]) => {
    switch (key) {
      case "facebook":
        return <FacebookIcon />;
      case "twitter":
        return <TwitterIcon />;
      case "instagram":
        return <InstagramIcon />;
      case "linkedin":
        return <LinkedinIcon />;
      case "youtube":
        return <YoutubeIcon />;
    }
  }, []);

  const socials = useMemo(() => {
    return state.socials
      ? Object.entries(state.socials)
          .filter(([key, value]) => !!value)
          .map(([key, value]) => (
            <SocialMediaButton key={key} href={value} target="_blank">
              {getIcon(key as keyof AppState["socials"])}
            </SocialMediaButton>
          ))
      : null;
  }, [state.socials, getIcon]);

  const pageContent = useMemo(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }

    if (currentItem) {
      return (
        <Content>
          {currentItem.description && <p>{currentItem.description}</p>}
          {currentPage && <MemoizedWordpressContentWrapper content={currentPage.content} />}
        </Content>
      );
    }

    switch (selectedNavigationItem) {
      case ReservedMenuIndices.About:
        return (
          <Content>
            <FormattedMessage
              tagName="p"
              id="sidebar.about.line1"
              defaultMessage="{platformName} has been created with the Stakeholder Engagement Platform of The Imagineers."
              description="The first line of the about page."
              values={{ platformName: state.name }}
            />
            <FormattedMessage
              tagName="p"
              id="sidebar.about.line2"
              defaultMessage="Go to <link>theimagineers.com/stakeholder-engagement-platform</link> for more information."
              description="The second line of the about page."
              values={{
                // @ts-ignore
                link: (chunks: ReactNode) => {
                  return (
                    <a
                      href="theimagineers.com/stakeholder-engagement-platform"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  );
                },
              }}
            />
          </Content>
        );

      case ReservedMenuIndices.Home:
        return homepage ? (
          <Content>
            <MemoizedWordpressContentWrapper content={homepage.content} />
            {homepage.pageLink && (
              <p className="link">
                <Link to={`/map/c/${homepage.pageLink.name}`}>
                  <FormattedMessage {...messages.readMore} />{" "}
                  <span className="icon">
                    <LinkArrowRight />
                  </span>
                </Link>
              </p>
            )}
          </Content>
        ) : null;

      case ReservedMenuIndices.Debug:
        return (
          <DebugContent />
        )
    }
  }, [currentItem, state.name, currentPage, selectedNavigationItem, homepage]);

  return (
    <Scroll style={{ overflow: interfaceState.sidebarOpen ? "hidden auto" : "hidden" }}>
      <ScrollInner ref={scrollRef}>
        {pageContent}
        {pageListSwitch(currentItem)}
      </ScrollInner>
      {!state.initializing && (
        <>
          {!!socials?.length && (
            <Content className="footer">
              <ParagraphSmall>{socials}</ParagraphSmall>
            </Content>
          )}
          <Content className="footer disclaimer">
            <ParagraphSmall>
              ©{" "}
              {state.companyUrl ? (
                <a href={state.companyUrl} target="_blank" rel="noreferrer">
                  {state.companyName}
                </a>
              ) : (
                state.companyName
              )}{" "}
              -{" "}
              <FormattedMessage
                id="sidebar.footer.attribution"
                defaultMessage="Created using the <link>Stakeholder Engagement Platform</link>"
                description="Attribution message The Imagineers"
                values={{
                  // @ts-ignore
                  link: (chunks: any) => {
                    return (
                      <a
                        href="https://theimagineers.com/stakeholder-engagement-platform"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {chunks}
                      </a>
                    );
                  },
                }}
              />
              {/* Bekijk hier de{" "} <a href="/disclaimer">Disclaimer</a> en het <a href="/privacy">Privacy statement</a>. */}
            </ParagraphSmall>
          </Content>
        </>
      )}
    </Scroll>
  );
}

/**
 * @todo - Maybe support a global faqlist and postlist (newslist)
 * @todo - Maybe support custom links better
 */
const pageListSwitch = (currentItem: MenuItem) => {
  switch (currentItem?.type) {
    case "info":
      return null;
    case "link":
      return (
        <Content>
          <a href={currentItem.url} target={currentItem.target}>
            {currentItem.name}
          </a>
        </Content>
      );
    case "layergroups":
    case "map":
      return <MapLayerGroups menuItem={currentItem} />;
    case "page":
      return <InformationPages menuItem={currentItem} />;
    case "panoramas":
    case "pano":
      return <PanoList menuItem={currentItem} />;
    case "participation":
      return <ParticipationList menuItem={currentItem} />;
    case "areas":
      return <Areas menuItem={currentItem} />;
    case "tours":
      return <ToursList menuItem={currentItem} />;
    case "phasing":
      return (
        <Content>
          <Timeline />
        </Content>
      );
  }

  if (currentItem?.type) console.warn(`Unknown menuItem type ${currentItem.type}`);

  return null;
};
