import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ratioToPercentage, toTimeDisplay } from "../../utils/mediaUtils";
import { MediaControl, MediaSpan, MediaTimeLine, PanoMediaControlsContainer } from "./PanoStyles";
import { ReactComponent as PauseIcon } from "../../images/pause-button.svg";
import { ReactComponent as PlayIcon } from "../../images/play-arrow.svg";
import { ReactComponent as MutedIcon } from "../../images/mute.svg";
import { ReactComponent as UnmutedIcon } from "../../images/unmute.svg";
import { useRerender } from "../../hooks/useRerender";
import { useTimeout } from "../../hooks/useTimeout";
import { FormattedMessage, useIntl } from "react-intl";
import { KrpanoType } from "../../libs/krpano";
import { useSessionStorage } from "../../hooks/useStorage";

interface Props {
  krpano: KrpanoType;
  audioName: string;
  url: string;
  loop: boolean;
}

interface KrpanoSound {
  DATA: any;
  volume: number;
  speed: number;
  loop: boolean;
  duration: number;
  position: number;
  muted: boolean;
  paused: boolean;

  onloaderror?: () => void;
  onplay?: () => void;
  onpause?: () => void;
  onstop?: () => void;
  onseeked?: () => void;
  oncomplete?: () => void;

  play(): void;
  pause(): void;
  toggle(): void;
  /** pause(); seek(0) */
  stop(): void;
}

const muteKey = "muteMedia";

export const PanoAudioControls = ({ krpano, audioName, url, loop }: Props) => {
  const audioPlugin = krpano.get("plugin[audioplayer]");
  const isMounted = useRef(true);
  const rerender = useTimeout(useRerender(isMounted), isMounted, 100);
  const [currentAudio, setCurrentAudio] = useState(audioName);
  const [mutedRaw, setMuted] = useSessionStorage(muteKey);
  const intl = useIntl();

  const muted = useMemo(() => mutedRaw ? JSON.parse(mutedRaw) : false, [mutedRaw]);

  useEffect(
    () => () => {
      isMounted.current = false;
      krpano.call("stopallsounds()");
    },
    [krpano]
  );

  const sound: KrpanoSound | null = useMemo(() => {
    if (!audioPlugin) return null;

    const sound: KrpanoSound | null =
      krpano.get(`sound[${audioName}]`) ||
      (krpano.call(`createsound(${audioName}, ${url}, false)`), krpano.get(`sound[${audioName}]`));

    if (!sound) return null;

    sound.loop = loop;

    // prevent destruction of the audio object after playback ends
    sound.DATA.hsound._onend.splice(0);

    if (!sound.duration) {
      sound.muted = true;
      sound.play();
      if (!sound.loop) sound.pause();
      sound.muted = false;
    }

    if (muted !== null) sound.muted = muted;

    return sound;
  }, [audioPlugin, krpano, audioName, url, loop, muted]);

  useEffect(() => {
    if (audioName !== currentAudio) {
      const sound: KrpanoSound = krpano.get(`sound[${currentAudio}]`);
      if (sound && !sound.paused) sound.stop();
      setCurrentAudio(audioName);
    }
  }, [audioName, currentAudio, setCurrentAudio, krpano]);

  const seek = useCallback(
    (event: SyntheticEvent<HTMLElement, MouseEvent>) => {
      if (!sound) return;

      const ratio = event.nativeEvent.offsetX / (event.target as HTMLElement).clientWidth;
      sound.position = ratio * sound.duration;
      rerender();
    },
    [sound, rerender]
  );

  const togglePlay = useCallback(() => {
    if (!sound) return;

    sound.toggle();
    rerender();
  }, [sound, rerender]);

  const toggleMute = useCallback(() => {
    if (!sound) return;

    setMuted(`${!sound.muted}`);
  }, [sound, setMuted]);

  useEffect(() => {
    if (isMounted.current && sound) {
      if (sound.paused) rerender.override(500);
      else rerender();
    }
  }, [sound, rerender]);

  const unmuteLabel = intl.formatMessage({
    id: "pano.media.unmute-aria-label",
    defaultMessage: "Turn off sound",
    description: "ARIA label for the mute button while the sound is currently muted.",
  });
  const muteLabel = intl.formatMessage({
    id: "pano.media.mute-aria-label",
    defaultMessage: "Turn on sound",
    description: "ARIA label for the mute button while the sound is not currently muted.",
  });
  const pauseLabel = intl.formatMessage({
    id: "pano.media.pause-aria-label",
    defaultMessage: "Pause",
    description: "ARIA label for the pause button.",
  });
  const playLabel = intl.formatMessage({
    id: "pano.media.play-aria-label",
    defaultMessage: "Play",
    description: "ARIA label for the play button.",
  });

  return (
    sound && (
      <PanoMediaControlsContainer>
        {!sound.loop && (
          <MediaTimeLine onClick={seek}>
            <div style={{ width: `${ratioToPercentage(sound.position, sound.duration)}%` }} />
          </MediaTimeLine>
        )}
        {!sound.loop && (
          <MediaControl onClick={togglePlay}>
            {sound.paused ? (
              <PlayIcon title={playLabel} aria-label={playLabel} />
            ) : (
              <PauseIcon title={pauseLabel} aria-label={pauseLabel} />
            )}
          </MediaControl>
        )}
        <MediaControl onClick={toggleMute}>
          {sound.muted ? (
            <MutedIcon title={unmuteLabel} aria-label={unmuteLabel} />
          ) : (
            <UnmutedIcon title={muteLabel} aria-label={muteLabel} />
          )}
        </MediaControl>
        {!sound.loop && (
          <MediaSpan>
            {toTimeDisplay(sound.position)} / {toTimeDisplay(sound.duration)}
          </MediaSpan>
        )}
        <MediaSpan>
          <FormattedMessage
            id="pano.media-type.audio"
            defaultMessage="Audio"
            description="Label depicting the currently playing media is a panoramic audio."
          />
        </MediaSpan>
      </PanoMediaControlsContainer>
    )
  );
};
