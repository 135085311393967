import classNames from "classnames";
import { useMemo, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppState } from "../../AppContext";
import { useContentUrl } from "../../hooks/useContentUrl";
import { useRouteState } from "../../hooks/useRouteState";
import { useInterfaceState } from "../../InterfaceContext";
import { MenuItem } from "../../types";
import { breakpoints } from "../../utils/styles";
import { StyledMenuLink } from "./MenuItemStyles";

export default function InformationPages({ menuItem }: { menuItem: MenuItem }) {
  const { state } = useAppState();
  const { interfaceState, setInterfaceState } = useInterfaceState();
  const routeState = useRouteState(true);
  const contentUrl = useContentUrl();

  const isBigScreen = useMediaQuery({ query: breakpoints[10] });

  const pageListItems = useMemo(() => {
    const shown = state.pages.filter(
      (page) => menuItem.children?.some((c) => c.page === page.id) || page.showInMenu
    );
    return shown.sort((pageA, pageB) => {
      if (!menuItem.children?.length) return 0;

      const indexA = menuItem.children.findIndex((c) => c.page === pageA.id);
      const indexB = menuItem.children.findIndex((c) => c.page === pageB.id);

      return indexA - indexB;
    });
  }, [menuItem, state.pages]);

  const closeSidebarMobile = useCallback(() => {
    if (!isBigScreen) {
      setInterfaceState({ ...interfaceState, sidebarOpen: false });
    }
  }, [interfaceState, isBigScreen, setInterfaceState]);

  return (
    <div>
      {pageListItems.map((page) => {
        return (
          <StyledMenuLink key={page.slug} to={contentUrl(page.slug)} onClick={closeSidebarMobile} className={classNames({
            active: page.slug === routeState.contentSlug
          })}>
            {page.title}
          </StyledMenuLink>
        );
      })}
    </div>
  );
}
