import { MercatorCoordinate } from "mapbox-gl";
import { useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import * as THREE from "three";
import { ThreeJsMapboxLayer } from "../../utils/three/ThreeJsMapboxLayer";

export interface ModelProps {
  url: string;
  latitude: number;
  longitude: number;
  altitude?: number;
  scale?: THREE.Vector3;
  rotate?: THREE.Euler;
  translate?: THREE.Vector3;
}

export const Model = ({
  url,
  latitude,
  longitude,
  altitude = 0,
  scale = new THREE.Vector3(1, 1, 1),
  rotate = new THREE.Euler(-Math.PI / 2, 0, 0),
}: ModelProps) => {
  const mapRef = useMap();
  const [id] = useState("three-" + crypto.randomUUID());

  const map = useMemo(() => mapRef.current?.getMap(), [mapRef]);

  useEffect(() => () => { map?.removeLayer(id) }, [id, map]);

  const coordinate = useMemo(
    () => MercatorCoordinate.fromLngLat([longitude, latitude], altitude),
    [latitude, longitude, altitude]
  );
  const translate = useMemo(
    () => new THREE.Vector3(coordinate.x, coordinate.y, coordinate.z),
    [coordinate]
  );
  const baseScale = useMemo(() => coordinate.meterInMercatorCoordinateUnits(), [coordinate]);

  const threeJsLayer = useMemo(
    () => map?.getLayer(id) as ThreeJsMapboxLayer ??
      new ThreeJsMapboxLayer({
        id,
        url,
        transform: {
          rotate,
          scale: scale.multiplyScalar(baseScale),
          translate,
        },
      }),
    [map, rotate, scale, translate, id, baseScale, url]
  );

  useEffect(() => {
    if (map && threeJsLayer.map !== map) {
      if (map.isStyleLoaded()) {
        map.addLayer(threeJsLayer);
      } else {
        map?.on("style.load", () => map.addLayer(threeJsLayer));
      }
    }
  }, [map, threeJsLayer]);

  return null;
};
