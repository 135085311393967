import styled, { keyframes } from "styled-components";
import { breakpoints } from "../../utils/styles";
import Icon from "../../shared/components/Icon";
import { Link } from "react-router-dom";
import userQuestionMarker from "../../images/markers/pinUser.svg";
import linkIcon from "../../images/linkArrowRight.svg";
import Close from "./../../images/close.svg";
import { StyledLayoutSidebar } from "../Sidebar/SidebarStyles";

export const CloseIconSpan = styled(Icon)`
  margin: auto;
  width: 18px;
  height: 18px;

  & svg {
    stroke: var(--color-neutral-70);
    transition: stroke 200ms var(--bezier-a);
  }
`;

export const PanoCloseLink = styled(Link)`
  position: absolute;
  z-index: 41;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  /* background-color: color-mod(var(--color-white) alpha(90%)); */
  background-color: #ffffffe6;
  transition: background-color 200ms var(--bezier-a);

  @media ${breakpoints[20]} {
    right: 32px;
    top: 32px;
  }

  &:hover {
    background-color: #ffffff99;
    /* background-color: color-mod(var(--color-white) alpha(60%)); */

    & ${CloseIconSpan} svg {
      stroke: var(--color-neutral-90);
    }
  }
`;

export const PanoContainer = styled.div`
  height: 100%;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  z-index: 40;

  p {
    margin: 0;
  }

  @media ${breakpoints[20]} {
    padding: 16px;
    grid-column: 1 / 4;

    &.editing {
      grid-column: 2 / 4;
      z-index: 9;
    }

    &.participating {
      grid-column: 1 / 4;
      z-index: 9; /* Show below sidebar for the box-shadow */
    }

    &.full {
      padding: 0;
      grid-column: 1 / 4;

      #pano {
        border-radius: 0;
      }
    }

    &.vr {
      padding: 0;

      #pano {
        border-radius: 0;
      }
    }
  }

  /* e6 = 90% transparency */
  background-color: #191825e6;
  color: var(--color-white);
  overflow: hidden auto;

  transition: transform 300ms var(--bezier-a), opacity 200ms var(--bezier-a);
  transform: scale(0.95);
  opacity: 0;
  pointer-events: none;

  /* &.is-open { */
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
  /* } */

  width: 100%;
  position: relative;

  display: grid;
  grid-template-rows: 1fr auto;

  & ${PanoCloseLink} {
    @media ${breakpoints[20]} {
      top: 32px;
      right: 32px;
    }
  }

  #pano {
    overflow: hidden;
    position: relative;
    height: 100%;
    background: var(--color-black);

    @media ${breakpoints[20]} {
      border-radius: var(--border-radius-default);
    }

    .pano-poi {
      cursor: default !important;

      > div > div > div > :first-child {
        max-width: calc(100% - 2rem);
      }
      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: relative;
        margin: auto;
        border: solid 5px transparent;
        border-bottom-color: var(--color-white);
        bottom: 10px;
      }

      .pano-poi-close {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: #19182566;
        text-align: -webkit-center;
        border: none;
        z-index: 1;
        cursor: pointer;

        &:hover {
          background-color: #19182599;
        }

        &:after {
          top: 7px;
          left: 7px;
          height: 18px;
          width: 18px;
          display: block;
          content: "";
          background: url(${Close});
        }
      }

      a {
        text-decoration: none;
        font-weight: 600;

        &:after {
          content: "";
          display: inline-block;
          background: url(${linkIcon});
          width: 20px;
          height: 20px;
          vertical-align: text-bottom;
        }
      }
    }
  }
`;

const sidebarOpen = `${StyledLayoutSidebar}.is-open~${PanoContainer}`;

export const Loader = styled.div`
  position: absolute;
  z-index: 41;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const PanoMedia = styled.div`
  position: relative;
  display: grid;
  overflow: hidden;

  &.user-question #pano {
    > div > div {
      cursor: url(${userQuestionMarker}) 16 57, auto !important;
    }
  }
`;

export const ScenarioSwitchContainer = styled.div`
  position: absolute;
  z-index: 41;
  bottom: -4px;
  left: 16px;
  transition: bottom 200ms var(--bezier-a), left 300ms var(--bezier-a);
  pointer-events: none;

  .show-video-controls &,
  .show-audio-controls & {
    bottom: 48px;
  }

  @media ${breakpoints[10]} {
    ${sidebarOpen} & {
      left: calc(16px + var(--sidebar-width));
    }
  }
`;

export const ScenarioLinkList = styled.ul`
  list-style: none;
  margin-bottom: var(--space-default);

  > * {
    pointer-events: all;
  }
`;

export const PanoExcerpt = styled.div`
  padding: var(--space-default);
  transition: padding 300ms var(--bezier-a);

  @media ${breakpoints[10]} {
    ${sidebarOpen} & {
      padding-left: calc(var(--space-default) + var(--sidebar-width));
    }
  }
`;

export const PlayButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  cursor: pointer;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  color: var(--color-neutral-90);
  padding: 16px 14px 14px 18px;
  background-color: #ffffffe6;
  transition: background-color 200ms var(--bezier-a);
  &:hover {
    background-color: #ffffff99;
  }
`;

export const PanoMediaControlsContainer = styled.div`
  display: none;
  pointer-events: none;

  .show-video-controls &,
  .show-audio-controls &,
  .show-audio-timeline & {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 7px var(--space-default) 0;
    margin: 0;
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
    z-index: 41;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
    height: 55px;
    overflow: visible;
    transition: height 200ms var(--bezier-a), padding 300ms var(--bezier-a);

    > * {
      pointer-events: auto;
    }
  }

  @media ${breakpoints[10]} {
    ${sidebarOpen} & {
      padding: 7px var(--space-default) 0 calc(var(--space-default) + var(--sidebar-width));
    }
  }
`;

export const MediaTimeLine = styled.div`
  display: block;
  width: 100%;
  height: 6px;
  transition: height 200ms var(--bezier-a);
  background: #00000099;
  cursor: pointer;

  > div {
    height: inherit;
    width: 0;
    position: relative;
    background: var(--color-primary-50);
    pointer-events: none;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 2px;
      transition: height 200ms var(--bezier-a);
      background: inherit;
      border-radius: 50%;
    }
  }

  &:hover {
    > div:after {
      width: 12px;
      height: 12px;
    }
  }
`;

export const MediaControl = styled.span`
  width: 40px;
  text-align: center;
  padding: var(--space-20);
  display: inline-block;
  cursor: pointer;

  svg {
    fill: currentColor;
  }
`;

export const MediaSpan = styled.span`
  padding: var(--space-20) var(--space-default);
  display: inline-block;
  vertical-align: text-bottom;
`;

export const ScenarioLink = styled.li`
  display: inline-block;
  font-size: var(--font-size-20);
  color: var(--color-neutral-70);
  border-radius: var(--border-radius-default);
  padding: 6px 12px 8px 12px;
  margin-right: var(--space-20);
  background-color: var(--color-neutral-10);
  line-height: var(--line-height-20);
  margin-bottom: 4px;
  position: relative;
  margin-top: var(--space-20);
  cursor: pointer;

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      color: var(--color-neutral-70);
    }
  }

  &:hover {
    background-color: var(--color-neutral-20);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    a {
      &:hover {
        color: var(--color-white);
      }
    }

    background-color: var(--color-primary-50);
    color: var(--color-white);
  }
`;

const fade = keyframes`
  0% {
    opacity: 100%;
    pointer-events: all;
  }
  
  50% {
    pointer-events: all;
    opacity: 100%;
  }
  
  100% {
    pointer-events: none;
    opacity: 0%;
  }
`;

export const PanoFade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 60;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  font-size: 42px;
  opacity: 0;
  pointer-events: none;
  animation: ${fade} 4s;

  > div {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    line-height: normal;
  }
`;

export const PanoMapWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: var(--space-default);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 70;
  overflow: hidden;

  > :first-child {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: var(--space-10);
    overflow: hidden;
  }
`;

export const PanoMapContainer = styled.div`
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
    margin: 0 15px 12px 0;
  }

  .mapboxgl-map {
    font-family: var(--font-family-body);
  }

  .mapboxgl-ctrl-bottom-left {
    display: none !important;
  }

  .mapbox-improve-map {
    display: none !important;
  }

  .mapboxgl-ctrl-attrib-inner a:after {
    display: none !important;
  }
`;
