import styled from "styled-components";
import { breakpoints } from "../../utils/styles";
import { ReactComponent as LinkArrowLeftIcon } from "../../images/linkArrowLeft.svg";
import React from "react";
import { Link } from "react-router-dom";

export const SidebarFocusHeadingHandle = styled.div`
  display: none;
  width: var(--space-40);
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
  height: var(--space-50);
  background: var(--color-neutral-10);
  align-self: center;

  > svg {
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%) scaleX(-1);
    position: relative;
    fill: none;
    stroke: black;
  }
`;

export const StyledLayoutSidebarFocus = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  overflow: hidden;
  z-index: 45;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-120%);
  transition: transform 300ms var(--bezier-a);

  width: calc(100vw + var(--space-40));
  display: grid;
  grid-template-columns: 100vw var(--space-40);

  > * {
    pointer-events: auto;
  }

  &.is-narrow {
    width: calc(80vw + var(--space-40));
    grid-template-columns: 80vw var(--space-40);
  }

  @media ${breakpoints[20]} {
    width: auto;
    padding: 8px;
    padding-right: 0;
    display: block;

    &.is-narrow {
      width: auto;
    }
  }

  &.is-open {
    transform: translateX(0);
    opacity: 1;
  }

  &.is-hidden {
    transform: translateX(calc(-100% + var(--space-40)));

    @media ${breakpoints[20]} {
      transform: translateX(0);
    }

    & ${SidebarFocusHeadingHandle} {
      display: block;
    }
  }
`;

export const StyledSidebarFocus = styled.div`
  background: var(--color-white);
  box-shadow: var(--box-shadow-30);

  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
  overflow: hidden;

  @media ${breakpoints[20]} {
    border-radius: var(--border-radius-default);
    height: auto;
    max-height: 100%;
  }
`;

export const SidebarFocusHeading = styled.div`
  padding: var(--space-inset-default);
  background: var(--color-neutral-10);

  @media ${breakpoints[20]} {
    border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  }
`;

export const SidebarFocusHeadingTitle = styled.div`
  display: flex;
  margin: var(--space-stack-25);
  align-items: flex-end;
  justify-content: space-between;

  .subtitle {
    display: block;
    font-size: var(--font-size-20);
    color: var(--color-neutral-50);
    white-space: nowrap;
  }
`;

const SidebarFocusHeadingProgress = styled.div`
  overflow: hidden;
  margin: var(--space-stack-default);
  background: var(--color-white);
  border-radius: var(--border-radius-default);
  height: 6px;
  width: 100%;

  > span {
    display: block;
    height: 6px;
    background-color: var(--color-primary-50);
    transition: width 300ms var(--bezier-a);
  }
`;

export const SidebarFocusButtonContainer = styled.div`
  border-top: 1px solid var(--color-neutral-10);
  width: 100%;
  padding: var(--space-inset-default);
  margin: var(--space-stack-default);
  display: flex;
  justify-content: space-between;

  > :last-child {
    margin-left: auto;
  }
`;

export const SidebarFocusContent = styled.div`
  padding: var(--space-inset-default);
  overflow-y: auto;
`;

export const SidebarFocusBackLink = ({
  to,
  label,
  onClick,
}: {
  to: string;
  label: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <p className="link" role="button">
      <Link to={to} onClick={onClick}>
        <>
          <span className="icon">
            <LinkArrowLeftIcon />
          </span>{" "}
          {label}
        </>
      </Link>
    </p>
  );
};

type SidebarFocusHeaderProps = React.PropsWithChildren<{
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  progress?: number;
}>;
export const SidebarFocusHeader = ({
  title,
  subtitle,
  progress,
  children,
}: SidebarFocusHeaderProps) => {
  return (
    <SidebarFocusHeading>
      {children}
      <SidebarFocusHeadingTitle>
        <h2 className="large" style={{ margin: 0 }}>
          {title}
        </h2>
        {subtitle && <span className="subtitle">{subtitle}</span>}
      </SidebarFocusHeadingTitle>
      {!!progress && (
        <SidebarFocusHeadingProgress>
          <span style={{ width: progress + "%" }}></span>
        </SidebarFocusHeadingProgress>
      )}
    </SidebarFocusHeading>
  );
};
