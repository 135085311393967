import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useAppState } from "../../AppContext";
import { useContentUrl } from "../../hooks/useContentUrl";
import { useFaqUrl } from "../../hooks/useFaqUrl";
import { useLayerGroupUrl } from "../../hooks/useLayerGroupUrl";
import { usePanoUrl } from "../../hooks/usePanoUrl";
import { useParticipationItemUrl } from "../../hooks/useParticipationItemUrl";
import { usePoiUrl } from "../../hooks/usePoiUrl";
import { useReplaceStatePath } from "../../hooks/useReplaceStatePath";
import { useTourUrl } from "../../hooks/useTourUrl";
import { useUpdateStateUrl } from "../../hooks/useUpdateStateUrl";
import { useInterfaceState } from "../../InterfaceContext";
import { isMapFaq, isPanoFaq } from "../../utils/faqUtils";
import { calculateViewport } from "../../utils/mapUtils";
import { ImageViewer } from "./ImageViewer";

type Props = { content: string };

/**
 * component to navigate correctly when clicking a link from wordpress
 */
const WordpressContentWrapper = ({ content }: Props) => {
  const updateStateUrl = useUpdateStateUrl();
  const replaceStatePath = useReplaceStatePath();
  const layerGroupUrl = useLayerGroupUrl();
  const panoUrl = usePanoUrl();
  const poiUrl = usePoiUrl();
  const tourUrl = useTourUrl();
  const contentUrl = useContentUrl();
  const faqUrl = useFaqUrl();
  const participationItemUrl = useParticipationItemUrl();
  const { state } = useAppState();
  const { interfaceState } = useInterfaceState();
  const history = useHistory();
  const [selectedImg, setSelectedImg] = useState<string | null>(null);

  const createLink = useCallback(
    (a: HTMLAnchorElement) => {
      const attributes = a.getAttribute("href")?.split("/").filter(Boolean);
      const type = attributes?.[0] ?? null;
      const slug = attributes?.[1] ?? null;
      const extra = attributes?.splice(2).join("/");
      if (!type || !slug || (attributes?.length && attributes.length < 2)) return a;
      if (
        ![
          "pano",
          "areas",
          "layergroups",
          "poi",
          "scenario",
          "tours",
          "participation",
          "faq",
          "menu",
        ].includes(type)
      )
        return a;
      let url = "";

      switch (type) {
        case "areas": {
          const area = state.areas.find((a) => a.slug === slug);
          if (!area) return;
          const minimumZoom = state.mapConfig.areaInflectionPoint;
          const viewport = calculateViewport(area.boundingBox);
          const zoom = viewport.zoom < minimumZoom ? minimumZoom : viewport.zoom;
          url = replaceStatePath({
            pathname: window.location.pathname,
            lat: viewport.latitude,
            long: viewport.longitude,
            zoom,
          });
          break;
        }
        case "layergroups":
          const layerGroup = state.map.layerGroups.find((l) => l.slug === slug);
          if (layerGroup) {
            url = layerGroupUrl(layerGroup);
          }
          break;
        case "tours":
          const tour = state.tours.find(
            (t) => t.slug === slug || t.points.find((p) => p.slug === slug)
          );
          if (tour) url = tourUrl(tour.slug);
          break;
        case "faq":
          const faq = state.faqs.find((f) => f.slug === slug);
          if (faq && isMapFaq(faq)) {
            url = faqUrl(faq.data.layerGroup.slug);
          } else if (faq && isPanoFaq(faq)) {
            url = faqUrl(faq.data.pano.slug);
          }
          break;
        case "participation":
          url = participationItemUrl(slug);
          break;
        case "scenario":
          url = updateStateUrl({ scenarioSlug: slug });
          break;
        case "pano":
          url = panoUrl(slug);
          break;
        case "poi":
          url = interfaceState.currentEntrance?.type !== "map" ? contentUrl(slug) : poiUrl(slug);
          break;
        case "menu":
          const numericSlug = Number(slug);
          if (!isNaN(numericSlug)) {
            url = updateStateUrl({ selectedNavigationItem: numericSlug });
          } else {
            url = updateStateUrl({
              selectedNavigationItem: state.menuItems.findIndex((item) =>
                [item.type, item.name].includes(slug)
              ),
            });
          }
          a.className = "sv_next_btn";
          break;
        default:
          url = contentUrl(slug);
          break;
      }

      if (url) {
        a.href = url;
        if (extra) {
          a.href = url.slice(0, url.includes("/@") ? url.indexOf("/@") : undefined) + "/" + extra;
        }
        a.setAttribute("customHref", "true");
      }
    },
    [
      contentUrl,
      faqUrl,
      layerGroupUrl,
      panoUrl,
      participationItemUrl,
      poiUrl,
      tourUrl,
      updateStateUrl,
      replaceStatePath,
      state.areas,
      state.faqs,
      state.map,
      state.mapConfig.areaInflectionPoint,
      state.menuItems,
      state.tours,
      interfaceState.currentEntrance,
    ]
  );

  const onClickHandler = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      const target = (event.target as Element).closest("a");
      if (target instanceof HTMLAnchorElement) {
        const href = target.getAttribute("href");
        if (href) {
          if (href.endsWith(".pdf")) {
            target.setAttribute("target", "_blank");
          } else if (target.getAttribute("customHref") === "true") {
            event.preventDefault();
            history.push(target.getAttribute("href")!);
          }
        }
        return;
      }
      const img = event.target as Element;
      if (img instanceof HTMLImageElement) {
        setSelectedImg(img.src);
      }
    },
    [history]
  );

  const div = document.createElement("div");
  div.innerHTML = content;
  div.querySelectorAll("a").forEach((a) => createLink(a));

  if (!div.innerHTML) return null;
  // If want to strip the html, use this: https://stackoverflow.com/questions/822452/strip-html-from-text-javascript
  return (
    <>
      <NoMarginDiv dangerouslySetInnerHTML={{ __html: div.innerHTML }} onClick={onClickHandler} />
      {selectedImg && <ImageViewer url={selectedImg} onClose={() => setSelectedImg(null)} />}
    </>
  );
};

const NoMarginDiv = styled.div`
  margin: 0 !important;
  /* margin: auto !important; */

  /* Table */
  table {
    border-collapse: separate;
    border-spacing: 0;
    margin: var(--space-stack-40);
    width: 100%;
  }

  th {
    padding: var(--space-25) 0;
    text-align: left;
    font-family: var(--font-family-heading);
    font-size: var(--font-size-10);
    color: var(--color-neutral-50);
    font-weight: 500;
  }

  td {
    padding: var(--space-20) 0;
    border-top: 2px solid var(--color-neutral-20);
  }

  tfoot td {
    border-top: 2px solid var(--color-neutral-30);
  }

  /* Image */
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  figure {
    margin: var(--space-stack-default);
    img {
      max-width: 100%;
      border-radius: var(--border-radius-20);

      + figcaption {
        color: var(--color-neutral-50);
        margin-top: var(--space-10);
        font-weight: 500;
        font-size: var(--font-size-20);
      }
    }
  }

  /* List */
  ul,
  ol {
    margin: var(--space-stack-default);
    padding: 0;
    list-style: none;

    li {
      line-height: var(--line-height-20);
      margin: 0;
      padding: 0;
      padding-left: 1.75rem;
      position: relative;

      &:before {
        position: absolute;
        left: 0.5rem;
        top: 0.55rem;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: var(--color-primary-50);
        content: " ";
      }
    }
  }

  ol {
    li {
      counter-increment: list__counter;

      &:before {
        content: counter(list__counter) ".";
        top: auto;
        height: none;
        width: none;
        background: none;
      }
    }
  }
`;

export const MemoizedWordpressContentWrapper = React.memo(WordpressContentWrapper);
