import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Button from "../shared/components/Button";
import { Modal } from "../shared/components/Modal";
import { isLocationQuestion, LocalPinModel, useSurveyState } from "../SurveyContext";
import { CreateLocationPickerAnswerModel } from "./Api";

type Props = {
  open: boolean;
  onClose: () => void;
  answerId: number | null;
};

export const SurveyArgumentModal = (props: Props) => {
  const { onClose, answerId } = props;

  const { surveyState, setSurveyState } = useSurveyState();
  const [comment, setComment] = useState("");

  const activePage = useMemo(
    () => surveyState.survey?.pages.find((p) => p.id === surveyState.activePageId),
    [surveyState.activePageId, surveyState.survey?.pages]
  );

  const activeLocationQuestionIds = useMemo(
    () => activePage?.elements.filter(isLocationQuestion).map((q) => q.id),
    [activePage]
  );

  const activeAnswers = useMemo(
    () =>
      surveyState.answers.filter((a) =>
        activeLocationQuestionIds?.includes(a.questionId)
      ) as CreateLocationPickerAnswerModel[],
    [activeLocationQuestionIds, surveyState.answers]
  );

  const answer = useMemo(
    () => activeAnswers.find((a) => a.pins.some((p) => (p as LocalPinModel).id === answerId)),
    [activeAnswers, answerId]
  );

  const pin = useMemo(() => answer?.pins.find((p) => (p as LocalPinModel).id === answerId), [answer, answerId]);

  const removePin = useCallback(() => {
    if (!answerId) return;
    setSurveyState((prevState) => {
      const newAnswers = prevState.answers.map((a) => {
        if (a.questionId !== answer?.questionId) return a;
        return {
          ...a,
          pins: (a as CreateLocationPickerAnswerModel).pins.filter((p) => (p as LocalPinModel).id !== answerId),
        };
      });
      return {
        ...prevState,
        answers: newAnswers,
      };
    });
    onClose();
  }, [answer, answerId, onClose, setSurveyState]);

  useEffect(() => {
    const existingComment = pin && pin.comment ? pin.comment : "";
    setComment(existingComment);
  }, [pin]);

  const updateText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value),
    [setComment]
  );

  const storeComment = useCallback(() => {
    if (!pin) return;
    pin.comment = comment;
    setSurveyState({
      ...surveyState,
      answers: surveyState.answers.map((a) => (a.questionId === answer?.questionId ? answer : a)),
      modalOpen: false,
    });
    setComment("");
    onClose();
  }, [pin, surveyState, setSurveyState, answer, setComment, comment, onClose]);

  // #TODO: i18n
  return (
    <Modal {...props}>
      <h2>
        <FormattedMessage
          id="survey.argument-model.explain.title"
          defaultMessage="Explain your answer"
        />
      </h2>
      <p>
        <FormattedMessage
          id="survey.argument-model.explain.description"
          defaultMessage="In the box below, please explain your answer."
        />
      </p>
      <input type="text" onChange={updateText} defaultValue={comment} />
      <ButtonContainer>
        <Button onClick={() => removePin()}>
          <FormattedMessage id="survey.argument-model.delete" defaultMessage="Delete" />
        </Button>
        <Button className="primary" onClick={() => storeComment()}>
          <FormattedMessage id="survey.argument-model.save" defaultMessage="Save" />
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;
