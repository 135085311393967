import React, { useEffect, useMemo, useState } from "react";
import { useAppState } from "../../AppContext";
import { visibleLegendItemLayers } from "../../utils/legendUtils";
import { BasicView } from "../Map/PopupTemplates/BasicView";
import { ListView } from "../Map/PopupTemplates/ListView";
import { InfoView } from "../Map/PopupTemplates/InfoView";
import { DashedLegendItem } from "../../images/legend/DashedLegendItem";
import { LineLegendItem } from "../../images/legend/LineLegendItem";
import { PointLegendItem } from "../../images/legend/PointLegendItem";
import { PolygonLegendItem } from "../../images/legend/PolygonLegendItem";
import { allowedPopups, computeProjectLayers } from "../../utils/legendUtils";
import { LegendList, LegendListItem, LegendListItemTitle } from "../Sidebar/MapLayerGroupsStyles";
import { DefaultLegendItem, LegendPopup } from "./MapStyles";
import { LinePaint } from "mapbox-gl";
import { useInterfaceState } from "../../InterfaceContext";

type Props = {
  forMap?: boolean;
};
type Layer = mapboxgl.Layer & { name: string; group?: string };

// @TODO mogelijk: kleur halen uit onze eigen legend items
/**
 * temp config till popup is implemented in wordpress
 * set popupmode to InfoView or BasicView(InfoView doesn't work properly with older seps yet)
 * key needs to be an unique value
 * Leave sortby empty if sorting is not needed, else enter the key that needs to be sorted by
 */
const wordpressConfig = {
  popupmode: "a",
  sortBy: "huisnummer",
  key: "huisnummer",
  isClickable: true,
};

function PopupData({ layers, dataview }: { layers: Layer[]; dataview: boolean }) {
  const [selectedLayer, setSelectedLayer] = useState<Layer | null>(null);
  const { interfaceState } = useInterfaceState();

  useEffect(() => {
    setSelectedLayer(null);
  }, [interfaceState.legendClicked]);

  if (!layers) return null;

  if (layers.length > 1 && !selectedLayer) {
    return (
      <ListView
        layers={layers}
        isClickable={wordpressConfig.isClickable}
        selectLayer={setSelectedLayer}
        getLayerColor={getLayerColor}
        keyValue={wordpressConfig.key}
      />
    );
  }

  if (layers.length === 1 || selectedLayer) {
    if (dataview) {
      return <InfoView layers={selectedLayer ? [selectedLayer] : layers}></InfoView>;
    } else {
      return (
        <BasicView
          layers={selectedLayer ? [selectedLayer] : layers}
          getLayerColor={getLayerColor}
        />
      );
    }
  }

  return null;
}

export default React.memo(function LegendItems({ forMap }: Props) {
  const { state } = useAppState();
  const { interfaceState } = useInterfaceState();
  const legendClicked = interfaceState.legendClicked;
  const features = useMemo(() => legendClicked?.features ?? [], [legendClicked?.features]);
  const projectLayers = useMemo(() => computeProjectLayers(state.map), [state.map]);
  const isDatalayer = Boolean(
    features.length && features[0]?.properties?.hasOwnProperty("BAG-nr adres")
  );

  const layers = useMemo(() => {
    if (isDatalayer) {
      return visibleLegendItemLayers(
        features,
        projectLayers,
        state.map.layerGroups,
        wordpressConfig
      );
    } else {
      return allowedPopups(features, projectLayers, state.map.layerGroups);
    }
  }, [features, projectLayers, state.map.layerGroups, isDatalayer]);

  if (!legendClicked || !layers?.length) return null;

  const shownLayerIds = new Set<string>();
  const items = layers?.map((layer) => {
    if (shownLayerIds.has(layer.id)) {
      return null;
    }
    shownLayerIds.add(layer.id);
    return (
      <LegendListItem key={layer.id}>
        {getLegendItem(getLayerColor(layer), getLayerType(layer))}
        <LegendListItemTitle>{layer.name || layer.id}</LegendListItemTitle>
      </LegendListItem>
    );
  });

  const legendList = <LegendList>{items}</LegendList>;

  if (!layers.length) {
    return null;
  }

  if (forMap) {
    return (
      <LegendPopup
        latitude={legendClicked?.lngLat.lat}
        longitude={legendClicked?.lngLat.lng}
        closeButton={false}
        closeOnClick={false}
        //onClose is disabled to make Popup interactable
        //onClose={() => setInterfaceState({ ...interfaceState, legendClicked: null })}
      >
        {isDatalayer && <PopupData layers={layers} dataview={isDatalayer} />}
        {!isDatalayer && legendList}
      </LegendPopup>
    );
  }

  return <>{items}</>;
});

function getLayerColor(layer: any) {
  const colorString = layer.type + "-color";
  if (!layer.paint[colorString]) return `rgb(0,0,0,1)`;
  const { r, g, b, a } = layer.paint[colorString];
  const factor = (1 / a) * 255;
  return `rgb(${r * factor}, ${g * factor}, ${b * factor}, ${a})`;
}

function getLayerType(layer: mapboxgl.Layer) {
  if (layer.type === "line") {
    if (layer.paint && (layer.paint as LinePaint)["line-dasharray"]) {
      return "dashed";
    }
  }
  return layer.type;
}

const getLegendItem = (color: string, type?: mapboxgl.Layer["type"] | "dashed") => {
  switch (type) {
    case "symbol":
      return <PointLegendItem color={color} />;
    case "line":
      return <LineLegendItem color={color} />;
    case "dashed":
      return <DashedLegendItem color={color} />;
    case "fill":
      return <PolygonLegendItem color={color} />;
    default:
      return <DefaultLegendItem style={{ backgroundColor: color }} />;
  }
};
