export const localOrigins: string[] = [
  window.origin.endsWith("/") ? window.origin : window.origin + "/",
  "/"
];

if (process.env.NODE_ENV === "development") {
  if (process.env.REACT_APP_PROXY_HOST !== window.origin) {
    const proxyHost = process.env.REACT_APP_PROXY_HOST!;
    localOrigins.push(proxyHost.endsWith("/") ? proxyHost : proxyHost + "/");
  }
}

export const isLocalUrl = (url: string) => localOrigins.some((o) => url.startsWith(o));