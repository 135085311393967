import { useGeneratePath } from "./useGeneratePath";

export function useTourUrl() {
  const generatePath = useGeneratePath();

  const tourUrl = (tourSlug?: string, tourPointSlug?: string) => {
    return generatePath(
      {
        tourSlug,
        tourPointSlug,
        layerGroupSlug : undefined,
        panoSlug : undefined,
        poiSlug : undefined,
        contentSlug : undefined,
        faqSlug : undefined,
        participationItemSlug : undefined,
        scenarioSlug : undefined,
        projectSlug : undefined,
        phaseSlug : undefined,
      },
      false,
      true
    );
  };

  return tourUrl;
}
