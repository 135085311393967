import { ElementModel } from "./Api";

type Props = {
  question: ElementModel;
};

export const ElementQuestion = ({ question }: Props) => {
  return (
    <div>
      {question.title && <h3>{question.title}</h3>}
      {question.content && <p>{question.content}</p>}
    </div>
  );
};
