import type { KeyboardEvent } from 'react';

export function makeKeyDownHandler<T>(
  handler?: (event: KeyboardEvent<T>) => void
) {
  if (!handler) return undefined;

  return (event: KeyboardEvent<T>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handler(event);

      event.preventDefault();
    }
  };
}
