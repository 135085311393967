import {
  AppState,
  UseStateReturn,
  WordpressArea,
  WordpressBase,
  WordpressConfig,
  WordpressFaq,
  WordpressLayerGroup,
  WordpressMapFaqData,
  WordpressMenuItem,
  WordpressPage,
  WordpressPano,
  WordpressPanoFaqData,
  WordpressParticipationItem,
  WordpressPhasing,
  WordpressPoi,
  WordpressScenario,
  WordpressTour,
} from "./types";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import Axios from "axios";
import { generateConfigFromWordpress } from "./utils/configUtils";
import projects from "./dummyproject.json";
import { transformWordpressPages } from "./utils/pageUtils";
import { transformWordpressPanos } from "./utils/panoUtils";
import { transformWordpressPois } from "./utils/poiUtils";
import { transformWordpressParticipation } from "./utils/participationUtils";
import { transformWordpressScenarios } from "./utils/scenarioUtils";
import { transformWordpressLayerGroups } from "./utils/layerGroupUtils";
import { transformWordpressAreas } from "./utils/areaUtils";
import { transformWordpressTours } from "./utils/tourUtils";
import { transformWordpressFaqs } from "./utils/faqUtils";
import { transformWordpressMenu } from "./utils/menuUtils";
import { transformWordpressPhasings } from "./utils/phasingUtils";
import { useRestAuth } from "./hooks/useRestAuth";
import usePromise from "./hooks/usePromise";
import { Capability, useCapability } from "./hooks/useCapability";
import { useAuthenticatedState } from "./hooks/useAuthenticatedState";
import { Feature, useFeature } from "./hooks/useFeature";
import { getLocalStorage, setLocalStorage } from "./utils/corsUtils";

let configUrl = origin + "/wp-json/acf/v3/options/options";
let apiBaseUrl = origin + "/wp-json/wp/v2";
let menuUrl = origin + "/wp-json/the_imagineers/v1/menu";

if (process.env.NODE_ENV === "development") {
  configUrl = process.env.REACT_APP_PROXY_HOST + "/wp-json/acf/v3/options/options";
  apiBaseUrl = process.env.REACT_APP_PROXY_HOST + "/wp-json/wp/v2";
  menuUrl = process.env.REACT_APP_PROXY_HOST + "/wp-json/the_imagineers/v1/menu";
}

const noop = () => {};

// @ts-ignore
const initialState: AppState = { initializing: true };

const StateContext = createContext<{
  state: AppState;
  showDraft: boolean;
  setShowDraft: UseStateReturn<boolean>[1];
  showFuture: boolean;
  setShowFuture: UseStateReturn<boolean>[1];
  showPending: boolean;
  setShowPending: UseStateReturn<boolean>[1];
  showPrivate: boolean;
  setShowPrivate: UseStateReturn<boolean>[1];
  refresh: () => void;
}>({
  state: initialState,
  showDraft: false,
  setShowDraft: noop,
  showFuture: false,
  setShowFuture: noop,
  showPending: false,
  setShowPending: noop,
  showPrivate: false,
  setShowPrivate: noop,
  refresh: noop,
});

const currentLastVisit = getLocalStorage("lastVisit");

export const StateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, setState] = useState(initialState);
  const restAuth = useRestAuth();
  const canReadDraft = useCapability(Capability.ReadDraft);
  const [showDraft, setShowDraft] = useAuthenticatedState(false, Capability.ReadDraft);
  const [showFuture, setShowFuture] = useAuthenticatedState(false, Capability.ReadDraft);
  const [showPending, setShowPending] = useAuthenticatedState(false, Capability.ReadDraft);
  const [showPrivate, setShowPrivate] = useAuthenticatedState(false, Capability.ReadDraft);
  const participationAvailable = useFeature(Feature.Participation);
  const faqsAvailable = useFeature(Feature.Faqs);
  const phasingAvailable = useFeature(Feature.Phasing);
  const locationQuery = window.location.search;
  const [refreshToken, setRefreshToken] = useState(1);

  const [lastVisit] = usePromise(async () => {
    const lv = await currentLastVisit;
    await setLocalStorage("lastVisit", new Date().toISOString());
    return lv ? new Date(lv) : null;
  }, []);

  const refresh = useCallback(() => setRefreshToken((token) => ++token), [setRefreshToken]);

  const previewMode = useMemo(() => {
    const search = new URLSearchParams(locationQuery);
    return search.has("preview");
  }, [locationQuery]);

  useEffect(() => {
    setShowDraft(previewMode);
  }, [setShowDraft, previewMode]);

  const filter = useMemo(() => {
    const filter = ["publish"];
    if (showDraft) filter.push("draft");
    if (showFuture) filter.push("future");
    if (showPending) filter.push("pending");
    if (showPrivate) filter.push("private");
    return filter;
  }, [showDraft, showFuture, showPending, showPrivate]);

  const contentFilter = useCallback(
    ({ status }: { status: WordpressBase["status"] }) => filter.includes(status),
    [filter]
  );

  const contentQuery = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set("per_page", "100");
    searchParams.set("status", canReadDraft ? "any" : "publish");

    return searchParams.toString();
  }, [canReadDraft]);

  const [siteConfigRaw, siteConfigError] = usePromise(
    () => Axios.get<WordpressConfig>(configUrl),
    [refreshToken]
  );
  const [poisRaw, poisError] = usePromise(
    () => Axios.get<WordpressPoi[]>(`${apiBaseUrl}/poi/?${contentQuery}`, restAuth),
    [restAuth, contentQuery, refreshToken]
  );
  const [panosRaw, panosError] = usePromise(
    () => Axios.get<WordpressPano[]>(`${apiBaseUrl}/pano/?${contentQuery}`, restAuth),
    [restAuth, contentQuery, refreshToken]
  );
  const [phasingRaw, phasingError] = usePromise(
    () =>
      phasingAvailable
        ? Axios.get<WordpressPhasing[]>(`${apiBaseUrl}/phasing/?${contentQuery}`, restAuth)
        : Promise.resolve(undefined),
    [phasingAvailable, restAuth, contentQuery, refreshToken]
  );
  const [pagesRaw, pagesError] = usePromise(
    () => Axios.get<WordpressPage[]>(`${apiBaseUrl}/pages/?${contentQuery}`, restAuth),
    [restAuth, contentQuery, refreshToken]
  );
  const [layerGroupsRaw, layerGroupsError] = usePromise(
    () => Axios.get<WordpressLayerGroup[]>(`${apiBaseUrl}/layergroups/?${contentQuery}`, restAuth),
    [restAuth, contentQuery, refreshToken]
  );
  const [participationRaw, participationError] = usePromise(
    () =>
      participationAvailable
        ? Axios.get<WordpressParticipationItem[]>(
            `${apiBaseUrl}/participation/?${contentQuery}`,
            restAuth
          )
        : Promise.resolve(undefined),
    [participationAvailable, restAuth, contentQuery, refreshToken]
  );
  const [scenariosRaw, scenariosError] = usePromise(
    () => Axios.get<WordpressScenario[]>(`${apiBaseUrl}/scenario/?${contentQuery}`, restAuth),
    [restAuth, contentQuery, refreshToken]
  );
  const [areasRaw, areasError] = usePromise(
    () => Axios.get<WordpressArea[]>(`${apiBaseUrl}/areas/?${contentQuery}`, restAuth),
    [restAuth, contentQuery, refreshToken]
  );
  const [toursRaw, toursError] = usePromise(
    () => Axios.get<WordpressTour[]>(`${apiBaseUrl}/tours/?${contentQuery}`, restAuth),
    [restAuth, contentQuery, refreshToken]
  );
  const [faqRaw, faqError] = usePromise(
    () =>
      faqsAvailable
        ? Axios.get<WordpressFaq<WordpressMapFaqData | WordpressPanoFaqData>[]>(
            `${apiBaseUrl}/faq/?${contentQuery}`,
            restAuth
          )
        : Promise.resolve(undefined),
    [faqsAvailable, restAuth, contentQuery, refreshToken]
  );
  const [menuRaw, menuError] = usePromise(
    () => Axios.get<WordpressMenuItem[]>(menuUrl, restAuth),
    [restAuth, refreshToken]
  );

  useEffect(() => {
    [
      siteConfigError,
      poisError,
      panosError,
      pagesError,
      phasingError,
      layerGroupsError,
      participationError,
      scenariosError,
      areasError,
      toursError,
      faqError,
      menuError,
    ].forEach((error) => {
      if (error) console.warn(error);
    });
  }, [
    siteConfigError,
    poisError,
    panosError,
    pagesError,
    phasingError,
    layerGroupsError,
    participationError,
    scenariosError,
    areasError,
    toursError,
    faqError,
    menuError,
  ]);

  const pages = useMemo(
    () => pagesRaw?.data && transformWordpressPages(pagesRaw.data.filter(contentFilter)),
    [pagesRaw, contentFilter]
  );

  const phasing = useMemo(
    () => phasingRaw?.data && transformWordpressPhasings(phasingRaw.data.filter(contentFilter)),
    [phasingRaw, contentFilter]
  );

  const scenarios = useMemo(
    () =>
      scenariosRaw?.data && transformWordpressScenarios(scenariosRaw.data.filter(contentFilter)),
    [scenariosRaw, contentFilter]
  );

  const layerGroups = useMemo(
    () =>
      layerGroupsRaw?.data &&
      scenarios &&
      transformWordpressLayerGroups(layerGroupsRaw.data.filter(contentFilter), scenarios),
    [layerGroupsRaw, scenarios, contentFilter]
  );

  const panos = useMemo(
    () =>
      panosRaw?.data &&
      scenarios &&
      layerGroups &&
      transformWordpressPanos(panosRaw.data.filter(contentFilter), layerGroups, scenarios),
    [panosRaw, scenarios, layerGroups, contentFilter]
  );

  const pois = useMemo(
    () =>
      poisRaw?.data &&
      layerGroups &&
      panos &&
      transformWordpressPois(poisRaw.data.filter(contentFilter), layerGroups, panos, lastVisit),
    [poisRaw, layerGroups, panos, contentFilter, lastVisit]
  );

  const participation = useMemo(
    () =>
      participationRaw?.data &&
      layerGroups &&
      panos &&
      transformWordpressParticipation(
        participationRaw.data.filter(contentFilter),
        layerGroups,
        panos
      ),
    [participationRaw, layerGroups, panos, contentFilter]
  );

  const faqs = useMemo(
    () =>
      faqRaw?.data &&
      layerGroups &&
      panos &&
      scenarios &&
      transformWordpressFaqs(faqRaw.data.filter(contentFilter), layerGroups, panos, scenarios),
    [faqRaw, layerGroups, panos, scenarios, contentFilter]
  );

  const areas = useMemo(
    () =>
      areasRaw?.data &&
      layerGroups &&
      transformWordpressAreas(areasRaw.data.filter(contentFilter), layerGroups),
    [areasRaw, layerGroups, contentFilter]
  );

  const { tours, tourPoints } = useMemo(() => {
    if (toursRaw?.data && layerGroups && panos && scenarios) {
      return transformWordpressTours(
        toursRaw.data.filter(contentFilter),
        layerGroups,
        panos,
        scenarios
      );
    }
    return { tours: undefined, tourPoints: undefined };
  }, [toursRaw, layerGroups, panos, scenarios, contentFilter]);

  const config = useMemo(
    () =>
      siteConfigRaw?.data &&
      scenarios &&
      pois &&
      panos &&
      pages &&
      generateConfigFromWordpress(siteConfigRaw.data, scenarios, panos, pages),
    [siteConfigRaw, scenarios, panos, pages, pois]
  );

  const menuItems = useMemo(
    () =>
      (menuRaw?.data.length && transformWordpressMenu(menuRaw.data)) ||
      (config && config.menuItems),
    [menuRaw, config]
  );

  const defaultLayerGroupSlug = useMemo(() => layerGroups?.[0]?.slug || "", [layerGroups]);

  useEffect(() => {
    if (config) {
      setState({
        ...config,
        initializing: false,
        pois: pois || [],
        panos: panos || [],
        pages: pages || [],
        areas: areas || [],
        faqs: faqs || [],
        tours: tours || [],
        tourPoints: tourPoints || [],
        phasing: phasing || [],
        projects,
        scenarios: scenarios || [],
        participation: participation || [],
        map: {
          ...config.mapConfig,
          baseLayers: [],
          layerGroups: layerGroups || [],
          defaultLayerGroupSlug,
        },
        menuItems: menuItems || [],
      });
    }
  }, [
    config,
    pois,
    panos,
    pages,
    phasing,
    areas,
    faqs,
    tours,
    tourPoints,
    scenarios,
    participation,
    layerGroups,
    defaultLayerGroupSlug,
    menuItems,
  ]);

  return (
    <StateContext.Provider
      value={{
        state,
        showDraft,
        setShowDraft,
        showFuture,
        setShowFuture,
        showPending,
        setShowPending,
        showPrivate,
        setShowPrivate,
        refresh,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useAppState = () => useContext(StateContext);
