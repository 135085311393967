export const resolveLocale = () => {
  const locales = [...navigator.languages];
  const documentLocale = findLocale(document.documentElement.lang);

  if (documentLocale) return documentLocale;

  return locales.find(findLocale) ?? availableLocales[0];
}

export const loadLocale = (locale: string) => {
  if (availableLocales.includes(locale)) return import(`../data/locales/${locale}.json`);
  return import("../data/locales/en.json");
}

const findLocale = (locale: string) => {
  if (availableLocales.includes(locale)) return locale;
  locale = locale.substring(0, 2);
  return availableLocales.includes(locale) ? locale : false;
}

//#region locales - The contents of this region will automatically be adjusted when the available translations change.
const availableLocales = [
 "en",
 "fr",
 "nl",
 "sco"
];
//#endregion locales