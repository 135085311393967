export const breakpoints = {
  10: "(min-width: 600px)",
  20: "(min-width: 750px)",
  30: "(min-width: 1000px)",
  40: "(min-width: 1200px)",
  map: "(min-width: 640px)",
};

export const sidebarWidth = 365;

const validHexRegex = /^#([\da-f]{6}|[\da-f]{3})$/i;
const convertHexRegex = /([\da-f]{1,2})([\da-f]{1,2})([\da-f]{1,2})/i;
const rgbaRegex = /rgba?\(((?:\s*\d*\.?\d+\s*,\s*){2,3})(?:\d*\.?\d+\s*)?\)/;

export const addOpacity = (color: string, opacity: number) => {
  switch (true) {
    case validHexRegex.test(color):
      return `rgba(${color
        .match(convertHexRegex)!
        .slice(1)
        .map((channel: string) => parseInt(channel.length === 1 ? channel+channel : channel, 16))},${opacity})`;

    case rgbaRegex.test(color):
      return color.replace(rgbaRegex, `rgba($1${opacity})`);
  }

  return;
};
