import React from "react";
import styled from "styled-components";
import { breakpoints } from "./../../utils/styles";

type ActionListProps = {
  children: React.ReactNode;
};

const Div = styled.div`
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-30);
  border-radius: var(--border-radius-default);

  @media ${breakpoints[10]} {
    display: flex;
  }
`;

export default function ActionList({ children }: ActionListProps) {
  return <Div>{children}</Div>;
}
