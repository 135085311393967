import { useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { messages } from "../global-intl-messages";
import { useParticipationItemUrl } from "../hooks/useParticipationItemUrl";
import Button from "../shared/components/Button";
import { findAnswer, isFileUploadQuestion, isLocationQuestion, isTextQuestion, useSurveyState } from "../SurveyContext";
import { minimumPinsPlaced } from "../utils/surveyUtils";

export const SurveyNavigation = () => {
  const { surveyState, setSurveyState, onCompleteFn, api } = useSurveyState();
  const { survey, activePageId } = surveyState;
  const participationItemUrl = useParticipationItemUrl();
  const history = useHistory();

  const page = survey?.pages.find((p) => p.id === activePageId);
  const index = page ? survey?.pages.indexOf(page) ?? 0 : 0;

  const setActivePage = useCallback(
    (pageId: number) => {
      setSurveyState((prevState) => ({ ...prevState, activePageId: pageId }));
    },
    [setSurveyState]
  );

  const previousPageId = useMemo(() => {
    if (index === 0) return null;
    return survey?.pages[index - 1].id;
  }, [index, survey?.pages]);

  const nextPageId = useMemo(() => {
    if (index === (survey?.pages ? survey.pages.length - 1 : -1)) return null;
    return survey?.pages[index + 1].id;
  }, [index, survey?.pages]);

  const canContinue = useMemo(() => {
    if (!page) return false;
    return (
      page.elements
        .filter(isLocationQuestion)
        .every((e) => {
          const answer = surveyState.answers.find(findAnswer(e))!;
          return minimumPinsPlaced(e, answer);
        }) &&
      page.elements
        .filter(isTextQuestion)
        .every((e) => {
          const answer = surveyState.answers.find(findAnswer(e));
          if (!answer) return !e.required;
          return answer.text.length >= Number(e.required);
        }) &&
      page.elements
        .filter(isFileUploadQuestion)
        .every((e) => {
          const answer = surveyState.answers.find(findAnswer(e));
          if (!answer) return !e.required;
          return answer.amountOfFiles >= Number(e.required);
        })
    );
  }, [page, surveyState]);

  const onComplete = useCallback(async () => {
    onCompleteFn().then(async (r) => {
      if (r && r.status === 200) {
        await Promise.all(r.data.map(async (e) => {
          const files = surveyState.files?.[e.questionId];
          if (files?.length) {
            for (const file of files) {
              return api.files.uploadFile(e.answerId, { file }).catch(e => console.error(`[Tim.Survey.FileUpload]: Upload failed (${file.name})`));
            }
          } else {
            console.error(`[Tim.Survey.FileUpload]: No files found for question ${e.questionId}`);
          }
        }));
      }
      setSurveyState((prevState) => ({
        ...prevState,
        answers: [],
        activePageId: undefined,
        activeQuestionId: undefined,
        activePinId: undefined,
        files: {},
      }));
      history.push(participationItemUrl());
    });
  }, [api.files, history, onCompleteFn, participationItemUrl, setSurveyState, surveyState.files]);

  return (
    <StyledSurveyNavigation>
      <div>
        {previousPageId && (
          <Button onClick={() => setActivePage(previousPageId)}>
            <FormattedMessage {...messages.previousButton} />
          </Button>
        )}
      </div>
      <div>
        {nextPageId && (
          <Button
            onClick={() => setActivePage(nextPageId)}
            className={canContinue ? "primary" : "disabled"}
            disabled={!canContinue}
          >
            <FormattedMessage {...messages.nextButton} />
          </Button>
        )}
        {!nextPageId && (
          <Button onClick={onComplete} className={canContinue ? "primary" : "disabled"}>
            <FormattedMessage {...messages.finishButton} />
          </Button>
        )}
      </div>
    </StyledSurveyNavigation>
  );
};

const StyledSurveyNavigation = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  border-top: 1px solid var(--color-neutral-10);
  padding-top: 1rem;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 1rem;
`;
