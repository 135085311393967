import { useMemo } from "react";
import styled from "styled-components";
import { useSurveyState } from "../SurveyContext";
import { CreateTextAnswerModel, TextQuestionModel } from "./Api";

type Props = {
  question: TextQuestionModel;
};

export const OpenQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();

  const answer = useMemo(() => {
    return surveyState.answers.find((a) => a.questionId === question.id) as CreateTextAnswerModel;
  }, [surveyState.answers, question.id]);

  const update = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let answer = surveyState.answers.find(
      (a) => a.questionId === question.id
    ) as CreateTextAnswerModel;
    if (!answer) {
      answer = {
        questionId: question.id,
        type: "TextAnswer",
        text: e.target.value,
      } as CreateTextAnswerModel;
    } else {
      answer.text = e.target.value.substring(0, question.maxLength);
    }
    if (e.target.value.length > question.maxLength) {
      e.target.value = e.target.value.substring(0, question.maxLength);
    }
    setSurveyState((prevState) => ({
      ...prevState,
      answers: [...prevState.answers.filter((a) => a.questionId !== question.id), answer!],
    }));
  };

  return (
    <OpenQuestionContainer>
      {question.title && (
        <h3>
          {question.title} {question.required && "*"}
        </h3>
      )}
      {question.content && <p>{question.content}</p>}
      {question.multiline && <textarea rows={5} onChange={update} />}
      {!question.multiline && <input type="text" onChange={update} />}
      <CharacterLimitBox>
        {`(${answer?.text.length ?? 0} / ${question.maxLength})`}
      </CharacterLimitBox>
    </OpenQuestionContainer>
  );
};

const OpenQuestionContainer = styled.div`
  margin: 1rem 0;
`;

const CharacterLimitBox = styled.div`
  text-align: right;
  height: 0.5rem;
  margin: 0.5rem 0;
`;
