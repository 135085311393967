import classNames from "classnames";
import React, { useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppState } from "../../AppContext";
import { ClickOverlay } from "../../shared/components/ClickOverlay";
import Header from "../../shared/components/Header";
import IsMobile from "../../shared/components/IsMobile";
import { breakpoints } from "../../utils/styles";
import { MemoizedNavigation } from "../Navigation/Navigation";
import { ReactComponent as LinkArrowRight } from "../../images/linkArrowRight.svg";
import { Content, StyledLayoutSidebar, StyledSidebar } from "./SidebarStyles";
import SidebarToggle from "./SidebarToggle";
import { useInterfaceState } from "../../InterfaceContext";
import { SidebarBody } from "./SidebarBody";
import Skeleton from "react-loading-skeleton";
import { FormattedMessage } from "react-intl";

export const EmbedSidebar = () => {
  const { state } = useAppState();
  const { interfaceState, setInterfaceState } = useInterfaceState();
  const isBigScreen = useMediaQuery({ query: breakpoints[30] });

  const closeSidebarMobile = useCallback(() => {
    if (isBigScreen || !interfaceState.sidebarOpen) {
      return;
    }
    setInterfaceState({
      ...interfaceState,
      sidebarOpen: false,
    });
  }, [isBigScreen, interfaceState, setInterfaceState]);

  return (
    <>
      <IsMobile>
        {interfaceState.sidebarOpen && (
          <ClickOverlay
            onClick={(e) => {
              closeSidebarMobile();
              e.preventDefault();
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </IsMobile>
      <StyledLayoutSidebar
        className={classNames({
          "is-closed": !interfaceState.sidebarOpen,
          "is-open": interfaceState.sidebarOpen,
          "is-hidden": !!document.getElementById("question-el"),
        })}
      >
        <StyledSidebar>
          <SidebarToggle
            onClick={() =>
              setInterfaceState({
                ...interfaceState,
                sidebarOpen: !interfaceState.sidebarOpen,
              })
            }
          />
          <Header
            loading={state.initializing}
            logoPath={state.logo}
            alt={state.name}
            description={state.description}
          >
            {state.name}
          </Header>
          <MemoizedNavigation embed>
            <p className="link">
              <a href={window.location.origin} target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="embed.view-full-platform"
                  defaultMessage="View the full platform"
                  description="The label for a link for viewing the whole platform, displayed while the platform is embedded"
                />
                <span className="icon">
                  <LinkArrowRight />
                </span>
              </a>
            </p>
          </MemoizedNavigation>
          {state.initializing ? (
            <Content>
              <p>
                {/** @ts-ignore */}
                <Skeleton count={6} />
              </p>
            </Content>
          ) : (
            <SidebarBody />
          )}
        </StyledSidebar>
      </StyledLayoutSidebar>
    </>
  );
};
