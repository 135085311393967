import React from "react";
import ReactDOM from "react-dom";
import md5 from "md5";
import App from "./App";
import { Plausible } from "./libs/plausible";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "./utils/corsUtils";
import { Feature } from "./hooks/useFeature";
import { IntlProvider } from "react-intl";
import { loadLocale, resolveLocale } from "./utils/intlUtils";
import { WordpressConfig } from "./types";

let configUrl = origin + "/wp-json/acf/v3/options/options";

if (process.env.NODE_ENV === "development") {
  configUrl = process.env.REACT_APP_PROXY_HOST + "/wp-json/acf/v3/options/options";
}

const validateCode = async (attempt = 0): Promise<boolean> => {
  let bodyTag = document.querySelector("body");
  if (bodyTag?.dataset.protected) {
    let code = await getLocalStorage("code");
    if (!code) code = prompt("Please enter your access code:", "");
    if (code === md5(document.title).toString().substr(0, 6)) {
      await setLocalStorage("code", code);
      return true;
    } else {
      await removeLocalStorage("code");
      if (++attempt < 3 && code !== null) {
        alert("Invalid code, try again.");
        return validateCode(attempt);
      }
      if (code !== null) {
        alert("Invalid code.");
      }
      return false;
    }
  }
  return true;
};

validateCode().then(async (value) => {
  if (!value) return;

  Plausible(window);

  if (process.env.NODE_ENV === "development") {
    const config: WordpressConfig = await fetch(configUrl).then((x) => x.json());
    document.documentElement.lang = config.acf.language;
    const featureInput = document.querySelector("input[name=_wpftrs]") as HTMLInputElement;
    const activeFeatures = Object.values(Feature).filter(
      (feature) => !(config as any).acf[`disable_${feature}`]
    );
    featureInput.value = activeFeatures.join();
  }

  const locale = resolveLocale();
  const messages = await loadLocale(locale);

  ReactDOM.render(
    <React.StrictMode>
      <IntlProvider locale={locale} messages={messages} defaultLocale="en-US">
        <App />
      </IntlProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
