import React, { useMemo } from "react";
import { useAppState } from "../../AppContext";
import { MenuListItem } from "./MenuItem";
import classNames from "classnames";
import { usePanoUrl } from "../../hooks/usePanoUrl";
import { MenuItem } from "../../types";
import { useRouteState } from "../../hooks/useRouteState";

export default function PanoList({ menuItem }: { menuItem: MenuItem }) {
  const { state } = useAppState();
  const panoUrl = usePanoUrl();
  const { panoSlug } = useRouteState(true);

  const panoListItems = useMemo(() => {
    const shown = state.panos.filter(
      (pano) => menuItem.children?.some((c) => c.page === pano.id) || pano.showInNavigation
    );
    return shown.sort((panoA, panoB) => {
      if (!menuItem.children?.length) return 0;

      const indexA = menuItem.children.findIndex((c) => c.page === panoA.id);
      const indexB = menuItem.children.findIndex((c) => c.page === panoB.id);

      return indexA - indexB;
    });
  }, [menuItem, state.panos]);

  return (
    <div>
      {panoListItems.map((pano) => {
        return (
          <MenuListItem
            title={pano.title}
            key={pano.slug}
            className={classNames({ active: pano.slug === panoSlug })}
            to={panoUrl(pano.slug)}
          />
        );
      })}
    </div>
  );
}
