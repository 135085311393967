import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useAppState } from "../../AppContext";
import { messages } from "../../global-intl-messages";
import { useLocalStorage } from "../../hooks/useStorage";
import Button from "../../shared/components/Button";
import { DotSelection } from "../../shared/components/DotSelection";
import IsDesktop from "../../shared/components/IsDeskop";
import IsMobile from "../../shared/components/IsMobile";
import { Modal } from "../../shared/components/Modal";
import { breakpoints } from "../../utils/styles";

enum OnboardingPages {
  MAP = "map",
  LAYERS = "layers",
  MARKERS = "markers",
  PANO = "pano",
  TOUR = "tour",
  PARTICIPATION = "participation",
  AREA = "area",
}

const OnboardingStorageKey = "CompletedOnboarding";

export const OnboardModal = () => {
  const { state } = useAppState();
  const [pages, setPages] = useState([OnboardingPages.MAP]);
  const [currentPage, setCurrentPage] = useState(OnboardingPages.MAP);
  // eslint-disable-next-line
  const [completedOnboarding, setCompletedOnboarding] = useLocalStorage(OnboardingStorageKey);
  const [open, setOpen] = useState(false);

  // @TODO: fill with content and reenable
  // useEffect(() => {
  //   if (completedOnboarding !== undefined) setOpen(completedOnboarding === null);
  // }, [completedOnboarding, setOpen]);

  useEffect(() => {
    const tempPages = [OnboardingPages.MAP];
    if (state.pois.length || state.panos.length || state.participation.filter((l) => l.showInMenu).length) {
      tempPages.push(OnboardingPages.MARKERS);
    }
    if (state.areas.length) {
      tempPages.push(OnboardingPages.AREA);
    }
    if (
      state.map.layerGroups.filter((l) => l.showInMenu).length &&
      state.menuItems.find((i) => ["layergroups", "map"].includes(i.type))
    ) {
      tempPages.push(OnboardingPages.LAYERS);
    }
    if (state.panos.length) {
      tempPages.push(OnboardingPages.PANO);
    }
    if (state.tours.length && state.menuItems.find((i) => i.type === "tour")) {
      tempPages.push(OnboardingPages.TOUR);
    }
    if (state.participation.length && state.menuItems.find((i) => i.type === "participation")) {
      tempPages.push(OnboardingPages.PARTICIPATION);
    }
    setPages(tempPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const MapPage = () => {
    return (
      <StyledOnboardingPage>
        <img src={"https://via.placeholder.com/800x600?text=kaart"} alt="map" />
        <p>
          <IsMobile>
            <FormattedMessage
              id="onboarding.mobile.map"
              defaultMessage={`This is the map, it can be moved by dragging with your fingers.
              The map can be zoomed by using two fingers on the screen and moving them together to zoom in, or apart to zoom out.
              Alternatively, the map can be zoomed in or out using the ''+'' and ''-'' buttons on the right-bottom side of the map.`}
              description="Description for how to operate the map using a mobile device."
            />
          </IsMobile>
          <IsDesktop>
            <FormattedMessage
              id="onboarding.desktop.map"
              defaultMessage={`This is the map, it can be moved by dragging the mouse with the left button pressed.
              The map can be zoomed in or out by scrolling, or by using the ''+'' and ''-'' buttons on the right-bottom side of the map.`}
              description="Description for how to operate the map using a desktop device."
            />
          </IsDesktop>
        </p>
      </StyledOnboardingPage>
    );
  };

  const MarkerPage = () => {
    return (
      <StyledOnboardingPage>
        <img src={"https://via.placeholder.com/800x600?text=iconen"} alt="mapmarkers" />
        <FormattedMessage
          tagName="p"
          id="onboarding.markers"
          defaultMessage={`On the map, icons are displayed which can be selected.
          Selecting them can have different effects, like showing more information,
          navigating to a panoramic image, or open possibilities to share your opinion about the subject.`}
          description="Description about the different icons on the map."
        />
      </StyledOnboardingPage>
    );
  };

  const AreasPage = () => {
    return (
      <StyledOnboardingPage>
        <img src={"https://via.placeholder.com/800x600?text=gebieden"} alt="areas" />
        <FormattedMessage
          tagName="p"
          id="onboarding.areas"
          defaultMessage={`When the map is zoomed out far enough, the markers will be replaced by different labels.
          Clicking on these will help zooming back in on specific areas of the project.`}
          description="Description about the area labels on the map."
        />
      </StyledOnboardingPage>
    );
  };

  const LayersPage = () => {
    return (
      <StyledOnboardingPage>
        <img src={"https://via.placeholder.com/800x600?text=kaartlagen"} alt="layers" />
        <FormattedMessage
          tagName="p"
          id="onboarding.menu.map"
          defaultMessage={`In the sidebar, the menu is displayed. When ''{label}'' is selected,
          you can select and view different maplayers about different aspects of the project.`}
          description="Description about the map submenu."
          values={{ label: state.menuItems.find((m) => m.type === "map")!.name }}
        />
      </StyledOnboardingPage>
    );
  };

  const PanosPage = () => {
    return (
      <StyledOnboardingPage>
        <img src={"https://via.placeholder.com/800x600?text=bolfoto"} alt="panos" />
        <FormattedMessage
          tagName="p"
          id="onboarding.panos"
          defaultMessage={`The photos in this platform are panoramic images, this means that imagery is available at all sides.
          You can look into other directions by rotating the image. This can be done by dragging or by using the arrow keys on your keyboard.`}
          description="Description about panos."
        />
        {state.scenarios.length > 1 && (
          <FormattedMessage
            tagName="p"
            id="onboarding.pano-scenarios"
            defaultMessage={`At the bottom left side of a photo, sometimes different scenarios can be selected.
            Selecting a different scenario will change the imagery in order to get a clear idea of different possibilities of the project.`}
            description="Description about scenarios."
          />
        )}
      </StyledOnboardingPage>
    );
  };

  const ToursPage = () => {
    return (
      <StyledOnboardingPage>
        <img src={"https://via.placeholder.com/800x600?text=tours"} alt="tours" />
        <FormattedMessage
          tagName="p"
          id="onboarding.menu.tours"
          defaultMessage={`When ''{label}'' is selected in the menu,
          you can select and view different tours. A tour will guide you through (a part of) the project, while giving more information about the aspects shown.`}
          description="Description about the tours submenu."
          values={{ label: state.menuItems.find((m) => m.type === "tours")!.name }}
        />
      </StyledOnboardingPage>
    );
  };

  const ParticipationPage = () => {
    return (
      <StyledOnboardingPage>
        <img src={"https://via.placeholder.com/800x600?text=inspraak"} alt="participation" />
        <FormattedMessage
          tagName="p"
          id="onboarding.menu.participation"
          defaultMessage={`When ''{label}'' is selected in the menu,
          you can select and enter different surveys. These will give you the possibility to share your opinion about the project.`}
          description="Description about the participation submenu."
          values={{ label: state.menuItems.find((m) => m.type === "participation")!.name }}
        />
        <FormattedMessage
          tagName="p"
          id="onboarding.menu.participation-markers"
          defaultMessage={`In some occasions, green pins are shown on the map which can be selected as well to navigate to a survey.`}
          description="Description about participation markers on the map."
          values={{ label: state.menuItems.find((m) => m.type === "participation")!.name }}
        />
      </StyledOnboardingPage>
    );
  };

  const pagesMap = new Map([
    [OnboardingPages.MAP, MapPage],
    [OnboardingPages.MARKERS, MarkerPage],
    [OnboardingPages.AREA, AreasPage],
    [OnboardingPages.LAYERS, LayersPage],
    [OnboardingPages.PANO, PanosPage],
    [OnboardingPages.TOUR, ToursPage],
    [OnboardingPages.PARTICIPATION, ParticipationPage],
  ]);

  const Page = pagesMap.get(currentPage);
  const nextPage = pages[pages.indexOf(currentPage) + 1];

  if (!Page) {
    return null;
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Page />
      <Navigation>
        <DotSelection
          items={pages}
          selectedItem={currentPage}
          onSelect={(i) => setCurrentPage(i as OnboardingPages)}
        />
        {nextPage ? (
          <Button onClick={() => setCurrentPage(nextPage)}>
            <FormattedMessage {...messages.nextButton} />
          </Button>
        ) : (
          <Button
            onClick={() => {
              setOpen(false);
              setCompletedOnboarding("true");
            }}
          >
            <FormattedMessage {...messages.closeButton} />
          </Button>
        )}
        <Button
          onClick={
            nextPage
              ? () => setCurrentPage(nextPage)
              : () => {
                  setOpen(false);
                  setCompletedOnboarding("true");
                }
          }
        >
          {nextPage ? (
            <FormattedMessage {...messages.nextButton} />
          ) : (
            <FormattedMessage {...messages.closeButton} />
          )}
        </Button>
      </Navigation>
    </Modal>
  );
};

const StyledOnboardingPage = styled.div`
  @media ${breakpoints[20]} {
    max-width: 500px;
  }

  img {
    object-fit: contain;
    max-width: calc(100% + 16px);
    margin: -8px -8px 8px -8px;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    max-width: 50%;
  }
`;
