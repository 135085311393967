import { AppState } from "../types";

export const shareView = (state: AppState, successMessage: string, failedMessage: string) => {
  if (!navigator.platform.includes("Win") && navigator.share) {
    navigator.share({
      url: window.location.href,
      text: state.description,
      title: state.name,
    });
    alert(successMessage);
  } else {
    try {
      const inputc = document.createElement("input");
      inputc.hidden = true; // prevent layout shift
      document.body.appendChild(inputc);
      inputc.value = window.location.href;
      inputc.focus();
      inputc.select();
      document.execCommand("copy");
      inputc.parentNode!.removeChild(inputc);
      alert(successMessage);
    } catch {
      alert(failedMessage);
    }
  }
};
