import { IntlShape } from "react-intl";
import { getTokenValue } from "../TokenStyles";
import { Hotspot, Pano, PanoLabel, PanoScenario } from "../types";

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-unused-vars */
const obcObj = require("../data/models/Online bezoekerscentrum.obj");
const obcMtl = require("../data/models/Online bezoekerscentrum.mtl");
const obcPanoJpg = require("../data/models/OBC.jpg");
const valeriusKwartierGlb = require("../data/models/Valeriuskwartier.glb");
const stucWhiteTga = require("../data/models/16-2/Wall_Stucco_White_21_97_diff__0_VRayBitmap.tga");
const kapstokObj = require("../data/models/Kapstok.obj");
const kapstokMtl = require("../data/models/Kapstok.mtl");
const turbineGlb = require("../data/models/Generic.glb");
const bedroomGlb = require("../data/models/bedroom.glb");

const tvObj = require("../data/models/TV op standaard.obj");
const tvMtl = require("../data/models/TV op standaard.mtl");
const tvVideoUrl = "https://threejs-gp.theimagineers.com/wp-content/uploads/2023/03/The-Imagineers-Website-Promo.mp4";



const HOUSE = {
  original: {
    fbx: require("../data/models/roelof/Fbx/House_Original_FBX.fbx"),
    gltf: require("../data/models/roelof/gltf/House_original_gltf.glb"),
    obj: require("../data/models/roelof/Obj/House_Original_OBJ.obj"),
    mtl: require("../data/models/roelof/Obj/House_Original_OBJ.mtl"),
  },
  adjusted: {
    fbx: require("../data/models/roelof/FBX_aangepast/Aangepast_FBX.fbx"),
    gltf: require("../data/models/roelof/gltf_aangepast/Aangepast_Gltf.glb"),
    obj: require("../data/models/roelof/Obj_aangepast/Aangepast_Obj.obj"),
    mtl: require("../data/models/roelof/Obj_aangepast/Aangepast_Obj.mtl"),
  },
  textures: {
    wood_antracite: require("../data/models/roelof/FBX_aangepast/Aangepast_FBX.fbm/houten_planken_antraciet.png"),
    wood_bump: require("../data/models/roelof/FBX_aangepast/Aangepast_FBX.fbm/houten_planken_bump.png"),
    spec: require("../data/models/roelof/FBX_aangepast/Aangepast_FBX.fbm/Spec.png"),
    specularMap: require("../data/models/roelof/FBX_aangepast/Aangepast_FBX.fbm/SpecularMap.jpg"),
    concrete: require("../data/models/roelof/FBX_aangepast/Aangepast_FBX.fbm/TexturesCom_Concrete_Polished_1K_albedo.tif"),
  },
  pbr: {
    fbx: require("../data/models/roelof/PBR/FBX_PBR/PBR_FBX.fbx"),
    gltf: require("../data/models/roelof/PBR/GLTF_PBR/PBR_GLTF.glb"),
    obj: require("../data/models/roelof/PBR/OBJ_PBR/PBR_OBJ.obj"),
    mtl: require("../data/models/roelof/PBR/OBJ_PBR/PBR_OBJ.mtl"),
  }
}

let origin = "";
if (process.env.NODE_ENV === "development" && process.env.REACT_APP_PROXY_HOST) {
  let proxyUrl = new URL(process.env.REACT_APP_PROXY_HOST);
  // Only change the origin if the orgin doesn't have port (for CORS reasons)
  if (!proxyUrl.port) origin = process.env.REACT_APP_PROXY_HOST.replace(/\/$/, "");
}
export interface BaseXmlProps {
  idletime: number;
  fov: number;
  hlookat: number;
  vlookat: number;
}
export const baseXml = ({ idletime, fov, hlookat, vlookat }: BaseXmlProps) => `
<krpano ${idletime ? `idletime="${idletime}"` : ""}>
	<contextmenu versioninfo="false">
    <item caption="© The Imagineers" />
  </contextmenu>
  <include url="/krpano/webvr.xml" />
  <plugin name="audioplayer"
    url.html5="/krpano/soundinterface.js"
    preload="true"
    volume="1.0"
    mute="false"
    autounlock="true"
  />
  <plugin name="three"
    url.html5="/krpano/seploader.js"
    keep="true"
  />
  <display
    autofullscreen="false"
    nofullscreenfallback="true"
  />
  <view
    limitview="range"
    fovmin="20"
    fovmax="100"
    fov="${fov}"
    hlookat="${hlookat}"
    vlookat="${vlookat}"
  />
  <control
    keycodesin="107,187"
    keycodesout="109,189"
  />
  <style name="poly_hotspot" fillalpha="0" fillcolor="${getTokenValue("--color-primary-50").replace(
    "#",
    "0x"
  )}" />
</krpano>
`;

const generateHotspotXml = (hotspots: Hotspot[]) => {
  return hotspots.reduce((acc: string, hotspot: Hotspot) => {
    const generateFn =
      hotspot.type && ["polyline", "polygon"].includes(hotspot.type)
        ? generatePolygonHotspotXml
        : generatePointHotspotXml;

    return acc + generateFn(hotspot);
  }, "");
};

const generatePointHotspotXml = (hotspot: Hotspot) => {
  const label = generateLabelURI(hotspot.title);
  const { yaw, pitch } = hotspot.coordinates[0];
  let width = 30;
  switch (hotspot.type) {
    case "high":
      width = 44;
      break;
    case "faq":
      width = 25;
      break;
    case "low":
      width = 50;
      break;
  }

  let oy = 0;
  if (hotspot.type === "faq" || hotspot.type === undefined) oy = -21;
  if (hotspot.action) oy = 0;

  const hotspotXml = `<hotspot name="${hotspot.slug}"
    url="data:image/svg+xml;utf8,${encodeURIComponent(hotspot.icon!)}"
    width="${width}"
    height="prop"
    ath="${yaw}"
    atv="${pitch}"
    oy="${oy}"
    capture="false"
    zorder="51"
    onout="tween(scale, 1);${hotspot.title ? `tween(hotspot[${hotspot.slug}-text].scale, 1)` : ""}"
    onhover="tween(scale, 1.1);${
      hotspot.title ? `tween(hotspot[${hotspot.slug}-text].scale, 1.1)` : ""
    }"
    linkurl="${hotspot.url}"
  />`;

  const labelXml = hotspot.title
    ? `<hotspot name="${hotspot.slug}-text"
    url="${label.svg}"
    width="${label.width}"
    height="prop"
    ath="${yaw}"
    atv="${pitch}"
    edge="left"
    ox="${hotspot.type === "high" ? 22 : 25}"
    oy="${hotspot.type === "faq" || hotspot.type === undefined ? -21 : 0}"
    onout="tween(hotspot[${hotspot.slug}].scale, 1); tween(scale, 1)"
    capture="false"
    zorder="51"
    onhover="tween(hotspot[${hotspot.slug}].scale, 1.1); tween(scale, 1.1)"
  />`
    : "";

  return hotspotXml + labelXml;
};

const generatePolygonHotspotXml = (hotspot: Hotspot) => {
  const label = generateLabelURI(hotspot.title);
  const medianYaw = hotspot.coordinates.reduce((a, c) => a + c.yaw, 0) / hotspot.coordinates.length;
  const minPitch = Math.max(...hotspot.coordinates.map((c) => c.pitch));

  const fillColor = getComputedStyle(document.documentElement)
    .getPropertyValue(hotspot.fill?.color ?? "--color-white")
    .replace("#", "0x");
  const fillOpacity = hotspot.fill?.opacity ?? 0;
  const borderColor = getComputedStyle(document.documentElement)
    .getPropertyValue(hotspot.border?.color ?? "--color-black")
    .replace("#", "0x");
  const borderOpacity = hotspot.border?.opacity ?? 0;
  const borderWidth = hotspot.border?.width ?? (hotspot.type === "polyline" ? 3 : 0);

  const hotspotXml = `<hotspot name="${hotspot.slug}"
    ${fillOpacity || !hotspot.allowHighlight ? "" : 'style="poly_hotspot"'}
    polyline="${hotspot.type === "polyline"}"
    fillcolor="${fillColor}"
    ${fillOpacity || !hotspot.allowHighlight ? `fillalpha="${fillOpacity}"` : ""}
    bordercolor="${borderColor}"
    borderalpha="${borderOpacity}"
    borderwidth="${borderWidth}"
    capture="false"
    zorder="${hotspot.type === "polyline" ? 50 : 49}"
    linkurl="${hotspot.url}"
  >
    ${hotspot.coordinates.reduce((acc, coord) => {
      return `${acc}<point ath="${coord.yaw}" atv="${coord.pitch}"/>`;
    }, "")}
  </hotspot>`;

  const topRight = hotspot.coordinates.reduce(
    (a, c) => ({
      yaw: Math.max(a.yaw, c.yaw),
      pitch: Math.min(a.pitch, c.pitch),
    }),
    { yaw: -Infinity, pitch: Infinity }
  );

  const topRightPoint = hotspot.coordinates.reduce((a, c) => {
    const currentClosestDistance = Math.sqrt(
      Math.pow(a.yaw - topRight.yaw, 2) + Math.pow(a.pitch - topRight.pitch, 2)
    );
    const currentDistance = Math.sqrt(
      Math.pow(c.yaw - topRight.yaw, 2) + Math.pow(c.pitch - topRight.pitch, 2)
    );
    return currentDistance < currentClosestDistance ? c : a;
  });

  const badgeSize = 20;
  const badge = hotspot.showBadge ? `<hotspot name="${hotspot.slug}-badge"
    html="1"
    type="text"
    css="font-size: ${getTokenValue("--font-size-30")}; font-weight: bold; color: ${getTokenValue("--color-white")}; text-align: center; line-height: ${badgeSize - 1}px;"
    bgroundedge="${badgeSize / 2}"
    bgcolor="${getTokenValue("--color-error-50").replace("#", "0x")}"
    width="${badgeSize}"
    height="${badgeSize}"
    oversampling="5"
    ath="${topRightPoint.yaw}"
    atv="${topRightPoint.pitch}"
    capture="false"
    zorder="51"
  />` : "";

  const labelXml = hotspot.title
    ? `<hotspot name="${hotspot.slug}-text"
    url="${label.svg}"
    width="${label.width}"
    height="prop"
    ath="${medianYaw}"
    atv="${minPitch}"
    edge="top"
    oy="5"
    capture="false"
    zorder="51"
  />`
    : "";

  return hotspotXml + badge + labelXml;
};

const generateLabelXml = (labels: PanoLabel[]) => {
  return labels.reduce((xml, label) => {
    const panoLabel = generateLabelURI(label.text);
    return (
      xml +
      `<hotspot name="${label.id}"
      url="${panoLabel.svg}"
      width="${panoLabel.width}"
      height="prop"
      ath="${label.yaw}"
      atv="${label.pitch}"
      handcursor="false"
      capture="false"
    />`
    );
  }, "");
};

/*

*/

const testThreeJsSceneXmls = [
  `
  <model name="pano" type="sphere" radius="250">
    <material name="pano" target="pano" source="${obcPanoJpg}" sourcetype="image" flip="x" />
  </model>
  <model name="sphere" type="sphere" radius="0.1">
    <material name="sphere" target="sphere" source="${obcPanoJpg}" sourcetype="image" />
  </model>
  `,  
  `
  <model name="box" type="box" size="2.5,2.5,2.5" tga="${stucWhiteTga}" tx="-5" ty="-2" tz="3" />
  <model name="house" obj="${HOUSE.adjusted.obj}" mtl="${HOUSE.adjusted.mtl}" ty="-1.8" tz="-5" scale="0.01" />
  `,
  `
  <model name="obc" obj="${obcObj}" mtl="${obcMtl}" ty="-1.8" />
  <model name="tv" obj="${tvObj}" mtl="${tvMtl}" ath="-90" ty="-1.8" depth="2.5">
    <material name="video" target="scherm" source="${tvVideoUrl}" sourcetype="video" flip="x" />
  </model>
  `
]


export const imagePanoXml = (
  pano: Pano,
  panoScenario: PanoScenario,
  hotspots: Hotspot[],
  panoramaFolder: string,
  intl: IntlShape
) => {
  return `<krpano>
  <image>
  <cube
  url="${origin}/panoramas/${panoramaFolder}/${
    panoScenario.resource
  }/mres_%s/l%l/%v/l%l_%s_%v_%h.jpg"
  multires="500,3000,1500,500"
  />
  </image>
  ${testThreeJsSceneXmls[[15,16,82].indexOf(pano.id)] || ''}
  <preview
    url="${origin}/panoramas/${panoramaFolder}/${panoScenario.resource}/preview.jpg"
  />
  ${generateHotspotXml(hotspots)}
  ${generateLabelXml(pano.labels)}
  ${generateVrControls(pano, panoScenario, intl)}
  ${pano.isIndoor ? indoorIntro(3) : outdoorIntro(6)}
  ${highlightPolygons(1)}
  ${dragHotspot()}
</krpano>
`;
};

export const videoPanoXml = (
  pano: Pano,
  panoScenario: PanoScenario,
  hotspots: Hotspot[],
  panoramaFolder: string,
  intl: IntlShape
) => {
  const resources = panoScenario.resource.map((resource: string) => {
    return `${origin}/panoramas/${panoramaFolder}/${resource}`;
  });
  return `<krpano>
  <image>
    <sphere url="plugin:video" />
  </image>
  <plugin name="video"
    url.html5="/krpano/videoplayer.js"
    panovideo="true"
    pausedonstart="true"
    videourl="${resources.join("|")}"
    loop="${pano.repeatVideo}"
    volume="1.0"
  />
  ${generateHotspotXml(hotspots)}
  ${generateLabelXml(pano.labels)}
  ${generateVrControls(pano, panoScenario, intl)}
  ${generateVideoControlHotspots()}
  ${highlightPolygons(1)}
  ${pano.isIndoor ? indoorIntro(3) : outdoorIntro(6)}
  ${dragHotspot()}
</krpano>
`;
};

const generateVrControls = (pano: Pano, panoScenario: PanoScenario, intl: IntlShape) => {
  const otherScenarios = pano.scenarios.filter(
    (s) => s !== panoScenario && panoScenario.showInNavigation
  );

  const exitVRLabel = generateLabelURI(
    intl.formatMessage({
      id: "pano.exit-vr",
      defaultMessage: "Exit VR",
      description: "Hotspot label for exiting VR, only shown while the user is currently in VR.",
    })
  );

  const exitVRButton = `<hotspot name="vr_exit"
    url="${exitVRLabel.svg}"
    width="${exitVRLabel.width}"
    height="prop"
    visible="false"
    ath="0"
    atv="70"
    zorder="100"
    onout="tween(scale, 1)"
    onhover="tween(scale, 1.1)"
    onclick="webvr.exitVR()"
    vr="true"
    vr_ui="true"
  />`;

  const scenarioLabels = otherScenarios.map((l) => generateLabelURI(l.title));

  if (otherScenarios.length) {
    const totalWidthWithMargins =
      scenarioLabels.reduce((acc, label) => acc + label.width + 20, 0) - 20;

    const currentScenarioLabel = generateLabelURI(panoScenario.title);

    const uiWidth = Math.max(totalWidthWithMargins, currentScenarioLabel.width);

    const uiSpacing = 15;

    const uiShape = generatePolygonShapeURI([
      [-uiWidth / 2, -uiSpacing],
      [uiWidth / 2, -uiSpacing],
      [uiWidth / 2, uiSpacing * 3],
      [-uiWidth / 2, uiSpacing * 3],
    ]);

    const scenarioSwitchUi = `<hotspot name="vr_scenario_switch_ui"
      url="${uiShape.svg}"
      width="${uiShape.width}"
      height="prop"
      visible="false"
      enabled="false"
      zorder="0"
      ath="0"
      atv="60"
      vr="true"
      vr_ui="true"
    />
    <hotspot name="vr_scenario_switch_ui_header_label"
      url="${currentScenarioLabel.svg}"
      width="${currentScenarioLabel.width}"
      height="prop"
      visible="false"
      enabled="false"
      zorder="100"
      ath="0"
      atv="60"
      oy="-${uiSpacing}"
      vr="true"
      vr_ui="true"
    />`;

    const scenarios = scenarioLabels.map((label, i) => {
      const position =
        scenarioLabels.slice(0, i).reduce((acc, label) => acc + label.width + 20, 0) -
        totalWidthWithMargins / 2;

      return `<hotspot name="vr_scenario_switch-${otherScenarios[i].slug}"
        url="${label.svg}"
        width="${label.width}"
        height="prop"
        visible="false"
        zorder="100"
        edge="left"
        ath="0"
        atv="60"
        ox="${position}"
        oy="${uiSpacing}"
        vr="true"
        vr_ui="true"
      />`;
    });

    return exitVRButton + scenarios.join("") + scenarioSwitchUi;
  } else {
    return exitVRButton;
  }
};

const outdoorIntro = (introTime: number) => {
  const secondaryAnimationTime = Number((introTime * 0.6).toPrecision(2));

  return `<action name="intro">
    Math.max(targetFov, 20, %3);
    Math.min(targetFov, 100);
    sub(starth, %1, 90);

    set(view,
      hlookat=get(starth),
      vlookat=90,
      fovmax=150,
      fov=150,
      camroll=0.000000,
      fisheye=1.00,
      fisheyefovlink=0.50,
      stereographic=true,
      architectural=0.0,
      architecturalonlymiddle=true,
      limitview=auto,
    );
    tween(view.hlookat, %1, ${introTime}, easeInOutQuad);
    delayedcall(
      ${introTime - secondaryAnimationTime},
      tween(view.vlookat, %2, ${secondaryAnimationTime}, easeInOutQuad);
      tween(view.fov, get(targetFov), ${secondaryAnimationTime}, easeInOutQuad, set(view.fovmax, 100));
      tween(view.fisheyefovlink, 0.3, ${secondaryAnimationTime}, easeInOutQuad);
      tween(view.fisheye, 0.0, ${secondaryAnimationTime}, easeInOutQuad);
    );
  </action>`;
};

const indoorIntro = (introTime: number) => {
  return `<action name="intro">
    set(targetH, %1);
    set(targetV, %2);
    Math.max(targetFov, 20, %3);
    Math.min(targetFov, 100);

    set(view,
      stereographic=true,
      fovmax=179,
      architectural=1.0,
      fisheye=0.35,
      fisheyefovlink=0.5,
      fov=calc(targetFov + 10),
      vlookat=calc(targetV + 10),
      hlookat=get(targetH),
      limitview=auto
    );

    tween(view.vlookat,  get(targetV), ${introTime / 2}, easeinoutquad);
    tween(view.fov,  get(targetFov), ${introTime}, easeinoutquad, set(view.fovmax, 100));
    tween(view.fisheyefovlink,  0.3, ${introTime}, easeinoutquad);
    tween(view.architectural,   0.0, ${introTime}, easeinoutquad);
    tween(view.fisheye,         0.0, ${introTime}, easeinoutquad);
  </action>`;
};

const highlightPolygons = (transitionTime: number) => {
  const fadeInTime = transitionTime / 4;
  const fadeOutTime = transitionTime - fadeInTime;

  return `<action name="highlight_polygons">
    set(transitioning, true);

    for(set(i,0), i LT hotspot.count, inc(i),
      if(hotspot[get(i)].style == poly_hotspot AND !hotspot[get(i)].storedfillcolor,
        set(hotspot[get(i)].storedfillcolor, get(hotspot[get(i)].fillcolor));
      );
    );

    tween(
      style[poly_hotspot].fillalpha,
      0.25,
      ${fadeInTime},
      easeOutQuad,
      tween(
        style[poly_hotspot].fillalpha,
        0,
        ${fadeOutTime},
        easeInQuad,
        set(transitioning, false);
      )
    );
    asyncloop(
      transitioning,
      for(set(i,0), i LT hotspot.count, inc(i),
        if(hotspot[get(i)].style == poly_hotspot,
          assignstyle(hotspot[get(i)], poly_hotspot)
        );
      ),
      for(set(i,0), i LT hotspot.count, inc(i),
        if(hotspot[get(i)].style == poly_hotspot,
          assignstyle(hotspot[get(i)], poly_hotspot);
          if(hotspot[get(i)].storedfillcolor,
            set(hotspot[get(i)].fillcolor, get(hotspot[get(i)].storedfillcolor));
          );
        );
      );
    );
  </action>`;
};

const dragHotspot = () => `<action name="draghotspot">
  set(control.usercontrol, off);
  txtadd(labelname, get(name), '-text');
  set(text, get(hotspot[get(labelname)]));
  spheretoscreen(ath, atv, hotspotcenterx, hotspotcentery, calc(mouse.stagex LT stagewidth/2 ? l : r));
  sub(drag_adjustx, mouse.stagex, hotspotcenterx);
  sub(drag_adjusty, mouse.stagey, hotspotcentery);
  asyncloop(pressed,
    events.dispatch(dragtick);
    sub(dx, mouse.stagex, drag_adjustx);
    set(text.dx, get(dx));
    sub(dy, mouse.stagey, drag_adjusty);
    set(text.dy, get(dy));
    screentosphere(dx, dy, ath, atv),
    set(control.usercontrol, all);
  );
</action>`;

const generateVideoControlHotspots = () => {
  return `<hotspot name="vr_toggle_play"
    url=""
    visible="false"
    width="50"
    height="prop"
    ath="0"
    atv="20"
    onout="tween(scale, 1)"
    onhover="tween(scale, 1.1)"
    vr="true"
    vr_ui="true"
  />`;
};

export const generateLabelURI = (txt: string) => {
  const fontSizeString = getTokenValue("--font-size-20");
  const fontSize = parseInt(fontSizeString);
  const fillColor = getTokenValue("--color-white");
  const fontFamily = getTokenValue("--font-family-body");
  const shadowColor = getTokenValue("--color-neutral-90");

  /**
   * Create a temporary element to determine svg's actual size.
   * @todo fix font-families are not considered in some OS and browsers
   */
  const temporaryElement = document.createElement("div");
  temporaryElement.textContent = txt;
  temporaryElement.setAttribute(
    "style",
    `
    visibility:hidden;
    font-weight: 600;
    font-size: var(--font-size-30);
    font-family: "sans-serif";
    position:absolute;
    padding: 3px;
    white-space: nowrap;
    box-sizing: border-box;
  `
  );
  document.body.appendChild(temporaryElement);

  const labelWidth = temporaryElement.offsetWidth;
  const labelHeight = temporaryElement.offsetHeight;
  const sharpenFactor = 5;

  document.body.removeChild(temporaryElement);

  const svg = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="${labelWidth * sharpenFactor}"
    height="${labelHeight * sharpenFactor}">
    <text style="text-shadow: 0px 0px 15px ${shadowColor}"
      font-weight="500"
      font-size="${fontSize * sharpenFactor}px"
      font-family="${fontFamily}"
      fill="${fillColor}"
      stroke="none"
      stroke-width="0"
      paint-order="stroke"
      alignment-baseline="middle"
      x="${2 * sharpenFactor}"
      y="${(labelHeight * sharpenFactor) / 2}"
    >
      ${encodeEntities(txt)}
    </text>
  </svg>
  `;
  return {
    svg: "data:image/svg+xml;utf8," + encodeURIComponent(svg),
    width: labelWidth,
  };
};

export const generatePolygonShapeURI = (points: [number, number][]) => {
  const sharpenFactor = 5;

  const minX = Math.min(...points.map(([x]) => x));
  const maxX = Math.max(...points.map(([x]) => x));
  const minY = Math.min(...points.map(([, y]) => y));
  const maxY = Math.max(...points.map(([, y]) => y));

  const width = maxX - minX;
  const height = maxY - minY;

  const shape = `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="${minX} ${minY} ${width} ${height}"
    width="${width * sharpenFactor}"
    height="${height * sharpenFactor}">
    <polygon
      fill="${getTokenValue("--color-neutral-50")}"
      fill-opacity="0.5"
      stroke="${getTokenValue("--color-neutral-30")}"
      stroke-opacity="1"
      stroke-width="1"
      points="${points.map(([x, y]) => `${x},${y}`).join(" ")}"
    />
  </svg>`;

  return {
    svg: "data:image/svg+xml;utf8," + encodeURIComponent(shape),
    width,
  };
};

const surrogate = document.createElement("textarea");
export const encodeEntities = (str: string): string => {
  surrogate.textContent = str;
  const encoded = surrogate.innerHTML;
  surrogate.textContent = "";
  return encoded;
};

// <!-- videourl="${origin}/panos/${panoramaFolder}/${pano.photoPath}" -->
